















































import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store.ts";

import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
import VueGoodshareTwitter from "vue-goodshare/src/providers/Twitter.vue";
import VueGoodshareReddit from "vue-goodshare/src/providers/Reddit.vue";
import VueGoodshareVkontakte from "vue-goodshare/src/providers/Vkontakte.vue";
import VueGoodshareViber from "vue-goodshare/src/providers/Viber.vue";
import VueGoodshareWhatsApp from "vue-goodshare/src/providers/WhatsApp.vue";

@Component({
  components: {
    VueGoodshareFacebook,
    VueGoodshareTwitter,
    VueGoodshareReddit,
    VueGoodshareVkontakte,
    VueGoodshareViber,
    VueGoodshareWhatsApp,
  },
})
export default class TheLegend extends Vue {
  /* ==PROPS== */
  // Map ID
  @Prop({ default: "" }) mapId!: string;
  @Prop({ default: "" }) mapTitle!: string;
  @Prop({ default: "fullUrl" }) urlType!: string;

  /* ==COMPUTED== */
  /**
   * @description Language object
   * @returns {any}
   */
  get lang(): any {
    if (store.state.maps[this.mapId].hasOwnProperty("lang")) {
      return store.state.maps[this.mapId].lang;
    } else {
      return null;
    }
  }

  get shareUrl(): string {
    if (this.urlType === "baseUrl") {
      return window.location.href;
    } else {
      return window.location.origin + window.location.pathname;
    }
  }
}
