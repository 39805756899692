import Vue from "vue";
import Vuex from "vuex";

import Map from "ol/Map";
import { IUser, ILayersTypes, ITypesDescriptions } from "@/interfaces.ts";

Vue.use(Vuex);

/* Please note!
This is a common store for ALL instances of App.vue on the page.
*/

interface Maps {
  [key: string]: MapObject;
}

interface MapObject {
  [key: string]: any;
}

export default new Vuex.Store({
  state: {
    maps: {} as Maps,
    publicKey: "6Lcalt8UAAAAAGNW6NDO4XIT7GgSwQqb9QgaOwyH", // Recaptcha 3; common for all maps
  },
  mutations: {
    // set property for id of the map
    setId(state, id: string) {
      Vue.set(state.maps, id, {});
    },

    // set langugage interface
    // TODO: Better type for lang (enum?)
    setLang(state, opt: { lang: string; id: string }) {
      Vue.set(state.maps[opt.id], "lang", opt.lang);
    },

    // setConf for sharing map configuration accross components
    // // TODO: Better type for conf
    setConf(state, opt: { conf: any; id: string }) {
      Vue.set(state.maps[opt.id], "conf", opt.conf);
    },

    // setMap for sharing accross components
    setMap(state, opt: { map: Map; id: string }) {
      Vue.set(state.maps[opt.id], "map", opt.map);
    },

    setLayersTypes(state, opt: { layersTypes: ILayersTypes, id: string}) {
      Vue.set(state.maps[opt.id], "layersTypes", opt.layersTypes);
    },

    setObjectTypesDescriptions(state, opt: { objectTypesDescriptions: ITypesDescriptions, id: string}) {
      Vue.set(state.maps[opt.id], "objectTypesDescriptions", opt.objectTypesDescriptions);
    },

    // Set user information for interaction user
    setUser(state, opt: { user: IUser; id: string }) {
      Vue.set(state.maps[opt.id], "user", opt.user);
    },

    // Set login statis
    setLogged(state, opt: { logged: boolean; id: string }) {
      Vue.set(state.maps[opt.id], "logged", opt.logged);
    },

    // Set logged user
    setLoggedUser(state, opt: { username: string; id: string }) {
      Vue.set(state.maps[opt.id], "loggedUser", opt.username);
    },
  },
  actions: {},
});
