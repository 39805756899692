import axios, { AxiosResponse, AxiosError } from "axios";

import { InterfaceSendFormOptions } from "@/interfaces.ts";

declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance;
    captchaOnLoad: () => void;
  }
}

interface ReCaptchaInstance {
  ready: (cb: () => any) => void;
  execute: (key: string, options: ReCaptchaExecuteOptions) => Promise<string>;
  render: (id: string, options: ReCaptchaRenderOptions) => any;
}

interface ReCaptchaExecuteOptions {
  action: string;
}

interface ReCaptchaRenderOptions {
  sitekey: string;
  size: "invisible";
}

export default function sendForm(formData: any, options: InterfaceSendFormOptions) {
  const message = {
    success: false, // will be used for errors only
    code: 100,
    error: "",
  };
  if (typeof options === "object" && !Array.isArray(options) && options !== null) {
    if (options.hasOwnProperty("publicKey")) {
      if (options.hasOwnProperty("action")) {
        if (options.hasOwnProperty("mailer")) {
          window.grecaptcha.execute(options.publicKey, { action: options.action }).then((token: string) => {
            const recapForm: FormData = new FormData();
            recapForm.append("token", token);
            recapForm.append("data", JSON.stringify(formData));
            axios
              .post(options.mailer, recapForm)
              .then((response: AxiosResponse) => {
                if (options.hasOwnProperty("callback")) {
                  options.callback(response.data);
                } else {
                  console.warn("no callback defined");
                }
              })
              .catch((error: AxiosError) => {
                console.error(error);
                message.code = 100;
                message.error = "Nedostupný PHP skript | PHP script unavalaible";
                options.callback(message);
              });
          });
        } else {
          console.error("options.mailer must be set");
          message.code = 101;
          message.error = "Mailer must be set | Mailer musí být nastaven";
          options.callback(message);
        }
      } else {
        console.error("options.action must be set");
        message.code = 102;
        message.error = "Action must be set | Action musí být nastavena";
        options.callback(message);
      }
    } else {
      console.error("options.publicKey must be set");
      message.code = 103;
      message.error = "publicKey must be set | publicKey musí být nastaven";
      options.callback(message);
    }
  } else {
    console.error("options must be object");
    message.code = 104;
    message.error = "options must be object | options musí být objekt";
    options.callback(message);
  }
}
