









































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Map from "ol/Map";
import BaseLayer from "ol/layer/Base";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";

@Component
export default class LayerList extends Vue {
  /* ==PROPS== */
  @Prop({ default: {} }) map!: Map;
  @Prop({ default: {} }) lang!: any;

  /* ==DATA== */
  // Array of layers in layerList
  layerList = [] as any[];

  /* ==COMPUTED== */
  get layerCollections(): BaseLayer[] {
    return this.map.getLayers().getArray(); // Generally can return  a layer or a layer group
  }

  get mapWidth() {
    return (this.map.getSize() as [number, number])[0];
  }

  // Compute max allowed height of layerList
  get layerListHeight() {
    return (this.map.getSize() as [number, number])[1] - 100;
  }

  /* ==WATCHERS== */
  // Recreate layerList if layers changed
  @Watch("layerCollections")
  onLayerCollectionsChanged() {
    this.createLayerList();
  }

  /* ==METHODS== */
  // Create Layer List
  createLayerList(): void {
    this.layerList.length = 0; // Going to create LayerList from the scratch
    this.layerCollections.forEach((collection: BaseLayer, index: number) => {
      const layerListItem = {} as any; // Describes one item in layerList
      if (collection instanceof LayerGroup) {
        // add LayerGroup to layerList
        layerListItem.type = "LayerGroup";
        layerListItem.title = collection.get("title");
        layerListItem.layers = [];
        collection
          .getLayers()
          .getArray()
          .forEach((layer) => {
            layerListItem.layers.push({
              title: layer.get("title"),
              visible: layer.get("visible"),
              opacity: layer.get("opacity"),
            });
          });
        this.layerList.push(layerListItem);
      } else if (collection instanceof TileLayer) {
        // add single layer to layerList
        layerListItem.type = "TileLayer";
        layerListItem.title = collection.get("title");
        layerListItem.visible = collection.get("visible");
        layerListItem.opacity = collection.get("opacity");
        this.layerList.push(layerListItem);
      } else if (collection.get("title") === "Edit layer" || collection.get("title") === "Tools layer") {
        // It is Edit or Tools layer, ignore it for now
      } else {
        console.warn(" WTF layerListItem?");
        console.warn(collection.get("title"));
      }
    });
  }

  // Change visibility of layer group
  listenChangeVisibilityOfGroup(visible: boolean, index: number, i: number): void {
    const layerGroup = this.layerCollections[index] as LayerGroup;
    layerGroup
      .getLayers()
      .getArray()
      [i].setVisible(visible);
    this.$emit("visibilityChanged");
  }

  // Change opacity of layer group
  listenChangeOpacityOfGroup(opacity: number, index: number, i: number): void {
    const layerGroup = this.layerCollections[index] as LayerGroup;
    layerGroup
      .getLayers()
      .getArray()
      [i].setOpacity(opacity);
  }

  // Change visibility of layer
  listenChangeVisibilityOfLayer(visible: boolean, index: number): void {
    this.layerCollections[index].setVisible(visible);
    this.$emit("visibilityChanged");
  }

  // Change opacity of layer
  listenChangeOpacityOfLayer(opacity: number, index: number): void {
    this.layerCollections[index].setOpacity(opacity);
  }

  /* ==LIFECYCLE HOOKS== */
  mounted(): void {
    this.createLayerList();
  }
}
