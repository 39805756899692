























































import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store.ts";
import Map from "ol/Map";
import BaseLayer from "ol/layer/Base";
import LayerGroup from "ol/layer/Group";
import VectorLayer from "ol/layer/Vector";
import { toContext } from "ol/render";
import { LineString, Point } from "ol/geom";
import { StyleFunction } from "ol/style/Style";

@Component
export default class TheLegend extends Vue {
  /* ==PROPS== */
  // Map ID
  @Prop({ default: "" }) mapId!: string;

  // Show legend?
  @Prop({ default: true }) show!: boolean;

  /* ==DATA== */
  // Legend content
  legendContent = [] as any[];

  // Layers styles
  layersStyles = {} as any;

  // device pixel ratio
  dpr = 1;

  /* ==COMPUTED== */
  /**
   * @description Langugage object
   * @returns {any}
   */
  get lang(): any {
    if (store.state.maps[this.mapId].hasOwnProperty("lang")) {
      return store.state.maps[this.mapId].lang;
    } else {
      return null;
    }
  }

  // Map
  get map(): Map {
    return store.state.maps[this.mapId].map;
  }

  // Layrs in map
  get layerCollections(): BaseLayer[] | null {
    if (this.map !== undefined) {
      return this.map.getLayers().getArray(); // Generally can return  a layer or a layer group
    } else {
      return null;
    }
  }

  /* ==METHODS== */
  // Emit event legendHidden
  handleLegendHide(): void {
    this.$emit("legendHidden");
  }

  // Create legend content
  createLegendContent(): void {
    this.legendContent = [];
    if (this.layerCollections !== null) {
      this.layerCollections.forEach((collection: BaseLayer, index: number) => {
        if (collection instanceof LayerGroup) {
          collection
            .getLayers()
            .getArray()
            .forEach((layer, layerIndex) => {
              const layerStyle = {} as any;
              if (layer instanceof VectorLayer && layer.get("visible")) {
                const styleFunc = layer.getStyle() as StyleFunction;
                const layerLegend = {} as any;
                const layerLegendDescription = {} as any; // For text descriptions
                const layerLegendGeom = {};
                const features = layer.getSource().getFeatures();
                features.forEach((feature) => {
                  const geomType = feature.getGeometry()!.getType();
                  if (!layerLegend.hasOwnProperty(geomType)) {
                    layerLegend[geomType] = [];
                    layerLegendDescription[geomType] = {};
                    layerStyle[geomType] = {};
                  }
                  layerLegend[geomType].push(feature.get("type"));
                  if (feature.get("typeDescription")) {
                    layerLegendDescription[geomType][feature.get("type")] = feature.get("typeDescription");
                  } else {
                    layerLegendDescription[geomType][feature.get("type")] = feature.get("type");
                  }
                  layerStyle[geomType][feature.get("type")] = styleFunc(feature, 500 * Math.pow(this.dpr, 10));
                });
                this.layersStyles["layer" + layerIndex] = layerStyle;
                Object.keys(layerLegend).forEach((geomType) => {
                  layerLegend[geomType] = (layerLegend[geomType] as string[]).filter((v, i, a) => a.indexOf(v) === i);
                });
                this.legendContent.push({
                  title: layer.get("title"),
                  index: "layer" + layerIndex,
                  layerLegend,
                  layerLegendDescription,
                });
              }
            });
        }
      });
    }
  }

  populateCanvases(): void {
    const canvases = document.getElementsByClassName("legend__canvas");
    Array.from(canvases).forEach((canvas: HTMLCanvasElement) => {
      if (canvas !== null) {
        const layer = canvas.dataset.layer as string;
        const geom = canvas.dataset.geom as string;
        const type = canvas.dataset.type as string;
        const context = canvas.getContext("2d");
        if (context !== null) {
          const style = this.layersStyles[layer][geom][type];
          if (style.getText()) {
            style.getText().setTextAlign("start");
            style.getText().setTextBaseline("top");
            let font = style.getText().getFont(); // scale font size by DPR
            const fontSizeOld = font.match(/\d+/);
            const fontSizeNew = fontSizeOld / this.dpr;
            font = font.replace(fontSizeOld, fontSizeNew);
            style.getText().setFont(font);
            style.getText().setText(this.lang.objects.objectTitle);
          }
          if (style.getStroke()) {
            const newWidth = style.getStroke().getWidth() / this.dpr;
            style.getStroke().setWidth(newWidth);
          }
          if (style !== undefined) {
            if (style.getText()) {
              context.fillStyle = style
                .getText()
                .getBackgroundFill()
                .getColor();
              context.fillRect(0, 0, canvas.width, canvas.height);
            }
            const vectorContext = toContext(context /* , { size: [20, 20] } */);
            vectorContext.setStyle(this.layersStyles[layer][geom][type]);
            switch (geom) {
              case "Point":
                vectorContext.drawGeometry(new Point([10 / this.dpr, 10 / this.dpr]));
                break;
              case "LineString":
                vectorContext.drawGeometry(
                  new LineString([
                    [0, 10 / this.dpr],
                    [100 / this.dpr, 10 / this.dpr],
                  ]),
                );
                break;
              default:
                console.warn("unknown geometry: " + geom);
                break;
            }
          } else {
            console.error("style is undefined");
          }
        } else {
          console.error("context is null");
        }
      } else {
        console.error("canvas is null");
      }
    });
  }

  /* ==LIFECYCLE HOOKS== */
  beforeUpdate(): void {
    if (this.show) {
      this.layersStyles = {};
      this.createLegendContent();
    }
  }

  updated(): void {
    if (this.show) {
      this.populateCanvases();
    }
  }

  mounted(): void {
    this.dpr = window.devicePixelRatio;
  }
}
