









































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import store from "@/store.ts";
import Feature from "ol/Feature";
import Select from "ol/interaction/Select";
import Collection from "ol/Collection";
import Modify from "ol/interaction/Modify";
import VectorLayer from "ol/layer/Vector";
import { toLonLat } from "ol/proj"; // for editing
import { Size } from "ol/size";

import { Fill, Stroke, Circle, Style } from "ol/style";

// Runtime template
import VRuntimeTemplate from "v-runtime-template";

// Send form by mail
import sendform from "@/scripts/sendform.ts";
import { singleClick } from "ol/events/condition";
import BaseLayer from "ol/layer/Base";
import LayerGroup from "ol/layer/Group";
import { ILayersTypes, IObjectTypeDescriptions, ITypesDescriptions } from "@/interfaces";
import Map from "ol/Map";
import { Coordinate } from "ol/coordinate";

@Component({
  components: {
    VRuntimeTemplate,
  },
})
export default class InfoWindow extends Vue {
  /* ==PROPS== */
  // Map ID
  @Prop({ default: "" }) mapId!: string;

  // langCode is used "indirectly" - by custom templates for infowindow and infowindowTitle
  @Prop({ default: "en" }) langCode!: string;

  // Path to template for infowindow content
  @Prop({ default: "" }) infowindow!: string;

  // Path to template for infowindow title
  @Prop({ default: "" }) infowindowTitle!: string;

  // List of features to display
  @Prop({ default: [] }) features!: Feature[];

  // List of layers corresponding to features to display - not used yet - for editors!
  @Prop({ default: [] }) layers!: VectorLayer[];

  // Edit layer (for anonymous editing)
  @Prop({ default: [] }) editLayer!: VectorLayer;

  // Position of click in pixelx
  @Prop({ default: [0, 0] }) pixel!: [number, number];

  // For newly added feature
  @Prop({ default: false }) forceEditMode!: boolean;

  // is editing enabled?
  @Prop({ default: true }) enableEdit!: boolean;

  // Template for editing
  @Prop({ default: "html/editTemplate.html" }) editTemplateUrl!: string;

  // Template for dbEditing
  @Prop({ default: "" }) dbEditTemplateUrl!: string;

  // Script for processing edited feature (sent by mail)
  @Prop({ default: "js/handleFeatureForEdit.js" }) handleEditedFeatureScript!: string;

  // Script for processing edited feature (written to database)
  @Prop({ default: "" }) handleSaveFeatureToDbScript!: string;

  /* ==DATA== */
  // Template for infowindow content
  template = "";

  // Template for infowindow title
  templateTitle = "";

  // Template for edit infowindow
  editTemplate = "";

  // DB template for edit infowindow
  dbEditTemplate = "";

  // Index of currently displayed feature in the list of features
  featureIndex = -1;

  // Id of currently displayed feature (used for link)
  featureId = "";

  // Link to open currently displayed feature
  featureLink = "";

  // Copy link result
  copySuccess = false;
  copyFail = false;

  // Style of edited feature
  editedFeatureStyle = new Style({
    image: new Circle({
      radius: 5,
      stroke: new Stroke({
        color: "rgba(255, 255, 255, 1)",
      }),
      fill: new Fill({
        color: "rgba(0, 255, 0, 0.7)",
      }),
    }),
    fill: new Fill({
      color: "rgba(0, 255, 0, 0.1)",
    }),
    stroke: new Stroke({
      color: "rgba(0, 255, 0, 1)",
      width: 2,
    }),
  });

  select = new Select({});

  // Modify geometry
  modify = null as null | Modify;

  // Zoom and center before zoom to
  originalZoom = null as null | number;
  originalCenter = null as null | Coordinate;

  // Edit mode
  editMode = false;
  editSendSuccess = null as null | boolean;
  editSendErrorMessage = "";
  sendTo = "info@franklinova-expedice.cz"; // Do not forget to change to your address!!!

  editSaveSuccess = null as null | boolean;
  editSaveErrorMessage = "";

  // Copy of current feature for editing
  editedFeature = null as null | Feature;

  // Layer of edited feature - allows to change the layer by logged user (must have its own method to change)
  editedLayers = [] as Array<{}>;

  /* ==COMPUTED== */
  // Recaptcha publice key
  get publicKey(): string {
    return store.state.publicKey;
  }

  // URL of PHP mailer script
  get mailer(): string | null {
    if (store.state.maps[this.mapId].hasOwnProperty("conf")) {
      return store.state.maps[this.mapId].conf.mailer;
    } else {
      return null;
    }
  }

  // Language object
  get lang(): any {
    return store.state.maps[this.mapId].lang;
  }

  // Returns Map object for this.mapId
  get map(): Map {
    return store.state.maps[this.mapId].map;
  }

  // Currently selected feature selected by featureIndex from features
  get featureDetail(): Feature | null {
    if (this.featureIndex > -1) {
      return this.features[this.featureIndex];
    } else {
      return null;
    }
  }

  // Size of map element
  get mapWindowSize(): Size {
    return this.map.getSize()!;
  }

  // Narow map window
  get isMapNarrow(): boolean {
    if (this.mapWindowSize![0] < 480) {
      return true;
    } else {
      return false;
    }
  }

  // URL of the map
  get appUrl(): string {
    return window.location.protocol + "//" + window.location.host + window.location.pathname;
  }

  // Is form valid (username and e-mail of sender)
  get invalidForm(): boolean {
    if (this.logged) {
      return (
        this.editedFeature!.get("type") === "" ||
        this.editedFeature!.get("layer") === "" ||
        this.editedFeature!.get("title").cs === "" ||
        this.editedFeature!.get("title").en === ""
      );
    } else {
      if (this.editedFeature!.get("user").username === "" || !this.validEmail(this.editedFeature!.get("user").email)) {
        return true;
      } else {
        return false;
      }
    }
  }

  // Login status
  get logged(): boolean {
    return store.state.maps[this.mapId].logged || false;
  }

  // Login status
  get loggedUser(): string | null {
    if (this.logged) {
      return store.state.maps[this.mapId].loggedUser;
    } else {
      return null;
    }
  }

  // layer types - TYPO!!! layerTypes
  get layersTypes(): ILayersTypes {
    return store.state.maps[this.mapId].layersTypes;
  }

  get objectTypesDescriptions(): ITypesDescriptions {
    return store.state.maps[this.mapId].objectTypesDescriptions;
  }

  // Database for editing
  get dbUrl(): string | null {
    if (store.state.maps[this.mapId].conf.hasOwnProperty("edit")) {
      return store.state.maps[this.mapId].conf.edit.dbUrl;
    } else {
      return null;
    }
  }

  /* ==WATCHERS== */
  // Watch changes in selected features
  @Watch("features")
  onFeaturesChanged(features: Feature[]) {
    if (features.length === 1) {
      this.featureIndex = 0;
    } else {
      this.featureIndex = -1;
    }
  }

  // Watch changes of edit mode
  @Watch("editMode")
  onEditModeChanged(newValue: boolean) {
    this.$emit("editModeChanged", newValue);
  }

  // Watch logged status changes and load related scripts
  @Watch("logged")
  async onLoggedChanged(newValue: boolean): Promise<void> {
    if (newValue) {
      // user is logged, changes will go to the database
      await this.$loadScript(this.handleSaveFeatureToDbScript);
    } else {
      // user is not logged, changes will go by e-mail
      await this.$loadScript(this.handleEditedFeatureScript);
    }
  }

  /* ==METHODS== */
  // Handle click on one item in list of found features
  handleClickInList(index: number): false {
    this.featureIndex = index;
    return false;
  }

  // Handle click on back-to-list in the selected feature.
  handleBackToList(): void {
    if (this.editMode) {
      this.handleCancelEditation(); // just in case we were in that mode
    }
    this.featureIndex = -1;
    // Here we should return to the previous center and zoom
    if (this.originalZoom !== null) {
      this.map.getView().setZoom(this.originalZoom);
      this.map.getView().setCenter(this.originalCenter!);
    }
  }

  // Zoom to selected feature
  handleZoomTo(): void {
    if (this.featureDetail !== null) {
      this.originalZoom = this.map.getView().getZoom()!;
      this.originalCenter = this.map.getView().getCenter()!;
      this.$emit("zoomTo", this.featureDetail.getId());
    }
  }

  // Create link to feature
  handleLinkTo(): void {
    if (this.featureDetail !== null && typeof this.featureDetail.getId() === "string") {
      this.featureId = this.featureDetail.getId() as string;
      const currentUrl = window.location.href;
      const positionOfId = currentUrl.indexOf(this.mapId);
      if (positionOfId > -1) {
        // id of the map is in urlParams
        const positionOfInsert = currentUrl.indexOf("=", positionOfId) + 1;
        this.featureLink =
          currentUrl.substring(0, positionOfInsert) + "!" + this.featureId + currentUrl.substring(positionOfInsert);
      } else {
        // Current URL (probably intentionally) does not contain urlParams for map
        this.featureLink = currentUrl;
        if (currentUrl.indexOf("?") > -1) {
          this.featureLink += "&";
        } else {
          this.featureLink += "?";
        }
        this.featureLink += this.mapId + "=!" + this.featureId;
      }
      this.$bvModal.show(this.mapId + "-feature-link");
    }
  }

  handleEdit(): void {
    // There is some double call, do not know why
    if (store.state.maps[this.mapId].hasOwnProperty("user")) {
      this.featureDetail!.set("user", store.state.maps[this.mapId].user);
    } else {
      this.featureDetail!.set("user", { username: "", email: "" });
    }

    // @ts-ignore
    this.editedFeature = featureToEdit(this.featureDetail, this.langCode) as Feature;

    // TODO Now we use only editLayer. For editors we will need to use layers - actually, probably not
    this.editLayer.getSource().clear();
    this.editLayer.getSource().addFeature(this.editedFeature as Feature);
    this.modify = new Modify({ features: new Collection([this.editedFeature as Feature]) });
    this.map.addInteraction(this.modify);
    this.editedFeature!.setStyle(this.editedFeatureStyle);
    // Store original layer of edited feature to property which will allow to change the layer by logged users
    // Cannot be done for new features!
    if (this.editedFeature.get("id")) {
      this.editedFeature.set("layer", this.layers[this.featureIndex].get("id"));
    }
    this.editedLayers = Object.keys(this.layersTypes).map((item) => {
      return { value: item, text: item };
    });
    console.log(this.editedLayers);
    this.editMode = true;
  }

  // Handle editation cancel
  handleCancelEditation(): void {
    this.map.removeInteraction(this.modify as Modify);
    // TODO Now we use only editLayers. For editors layers will be used
    this.editLayer.getSource().removeFeature(this.editedFeature as Feature);
    this.editMode = false;
    this.editSendSuccess = null;
    this.editSendErrorMessage = "";
    if (!this.editedFeature!.get("id")) {
      // it was a new feature
      this.handleCloseInfoWindow();
    }
  }

  // Handle sending changes
  handleSendChanges(feature: Feature): void {
    if (this.logged) {
      // Changes to be commited to the database
      // @ts-ignore
      const featureToDb = prepareFeatureForDb(this.editedFeature, toLonLat);
      // This object has the structure of database record, not the structure of GeoJSON!
      featureToDb.mod_by = this.loggedUser; // logged user
      console.log(featureToDb);
      // Scroll window content to top
      (this.$refs["infowindowbody-" + this.mapId] as HTMLElement).scrollTop = 0;
      if (feature.getId()) {
        axios
          .put(this.dbUrl + "/" + feature.getId(), featureToDb, {
            withCredentials: true,
          })
          .then(
            (response) => {
              console.log(response.data);
              this.editSaveSuccess = true;
            },
            (error) => {
              console.error(error);
              this.editSaveErrorMessage = error.response.status;
              this.editSaveSuccess = false;
            },
          );
      } else {
        featureToDb.created_by = featureToDb.mod_by;
        axios
          .post(this.dbUrl!, featureToDb, {
            withCredentials: true,
          })
          .then(
            (response) => {
              console.log(response.data);
              this.editSaveSuccess = true;
            },
            (error) => {
              console.error(error);
              this.editSaveErrorMessage = error.response.status;
              this.editSaveSuccess = false;
            },
          );
      }
    } else {
      // Changes to be sent by mail
      store.commit("setUser", { user: this.editedFeature!.get("user"), id: this.mapId });
      // @ts-ignore
      const dataToSend = editedFeatureToSend(
        this.editedFeature,
        this.langCode,
        this.lang,
        this.featureDetail,
        this.sendTo,
      );
      (this.$refs["infowindowbody-" + this.mapId] as HTMLElement).scrollTop = 0;
      sendform(dataToSend, {
        action: "sendmail",
        mailer: this.mailer as string,
        publicKey: this.publicKey,
        callback: this.handleResult,
      });
    }
  }

  // Handle closing of edited and saved feature
  handleCloseAndReload(): void {
    if (this.editedFeature!.get("id")) {
      // Edited already existing feature
      this.layers[this.featureIndex].once("postrender", () => {
        // We must set type descriptions
        const features = this.layers[this.featureIndex].getSource().getFeatures();
        setTypesDescriptions(features, this.objectTypesDescriptions[this.layers[this.featureIndex].get("id")]);
        console.log(features);
      });
      this.layers[this.featureIndex].getSource().refresh();

      if (this.layers[this.featureIndex].get("id") !== this.editedFeature!.get("layer")) {
        // Feature was moved to another layer
        this.map
          .getLayers()
          .getArray()
          .forEach((collection: BaseLayer, index: number) => {
            if (collection instanceof LayerGroup) {
              collection
                .getLayers()
                .getArray()
                .forEach((layer, layerIndex) => {
                  if (layer instanceof VectorLayer) {
                    console.log(layer.get("id"));
                    if (layer.get("id") === this.editedFeature!.get("layer")) {
                      layer.once("postrender", () => {
                        // We must set type descriptions
                        const features = layer.getSource().getFeatures();
                        setTypesDescriptions(features, this.objectTypesDescriptions[layer.get("id")]);
                        console.log(features);
                      });
                      console.log("new layer to be refreshed");
                      layer.getSource().refresh();
                      console.log("new layer refreshed");
                    }
                  }
                });
            }
          });
      }
    } else {
      // Added new feature - have to find proper layer
      this.map
        .getLayers()
        .getArray()
        .forEach((collection: BaseLayer, index: number) => {
          if (collection instanceof LayerGroup) {
            collection
              .getLayers()
              .getArray()
              .forEach((layer, layerIndex) => {
                if (layer instanceof VectorLayer) {
                  console.log(layer.get("id"));
                  if (layer.get("id") === this.editedFeature!.get("layer")) {
                    layer.once("postrender", () => {
                      // We must set type descriptions
                      const features = layer.getSource().getFeatures();
                      setTypesDescriptions(features, this.objectTypesDescriptions[layer.get("id")]);
                      console.log(features);
                    });
                    console.log("new layer to be refreshed");
                    layer.getSource().refresh();
                    console.log("new layer refreshed");
                  }
                }
              });
          }
        });
    }
    this.handleCloseInfoWindow();
  }

  // Handle result of sending changes
  handleResult(result: any): void {
    if (result.success) {
      this.editSendSuccess = true;
    } else {
      this.editSendErrorMessage = result.error;
      this.editSendSuccess = false;
    }
  }

  // Render content of infowindow
  showContent(): void {
    if (!this.editMode) {
      if (this.features.length > 0) {
        this.map.removeInteraction(this.select); // Must remove previous selection!
        if (this.features.length === 1) {
          // Must choose first and only feature
          this.featureIndex = 0;
        }
        if (this.featureIndex === -1) {
          // add all selected features to our selection
          this.select = new Select({
            features: new Collection(this.features),
            removeCondition: singleClick,
          });
        } else {
          // add selected feature of selected features to our selections
          this.select = new Select({
            features: new Collection([this.features[this.featureIndex]]),
            removeCondition: singleClick,
          });
        }
        this.map.addInteraction(this.select);
      }
    }
  }

  // Close infowindow
  handleCloseInfoWindow(): void {
    this.map.removeInteraction(this.select); // Odvybereme vše
    // this.editMode = false;
    if (this.editMode) {
      this.handleCancelEditation();
    }
    this.$emit("editModeChanged", this.editMode); // Watcher does not fire event (do not know why)
    this.$emit("close");
  }

  // Validate email
  validEmail(email: string): boolean {
    const re = /(.+)@(.+){2,}\.(.+){2,}/;
    return re.test(email.toLowerCase());
  }

  /* ==LIFECYCLE HOOKS== */
  created(): void {
    // Move map, if needed
    if (this.pixel[0] < 290 && !this.isMapNarrow) {
      // Move right
      const moveRight = 290;
      const view = this.map.getView();
      const center = view.getCenter();
      const resolution = view.getResolution();
      view.setCenter([center![0] - moveRight * resolution!, center![1]]);
    }

    // Load template for infowindow body
    axios.get(this.infowindow).then(
      (response) => {
        this.template = response.data;
      },
      (error) => {
        console.error(error);
      },
    );

    // Load template for infowindow titles
    axios.get(this.infowindowTitle).then(
      (response) => {
        this.templateTitle = response.data;
      },
      (error) => {
        console.error(error);
      },
    );

    // Load template for editing
    if (this.enableEdit) {
      axios.get(this.editTemplateUrl).then(
        (response) => {
          this.editTemplate = response.data;
        },
        (error) => {
          console.error(error);
        },
      );
      // load template for db editing, if defined
      if (this.dbEditTemplateUrl !== "") {
        axios.get(this.dbEditTemplateUrl).then(
          (response) => {
            this.dbEditTemplate = response.data;
          },
          (error) => {
            console.error(error);
          },
        );
      }
    }

    // Set sendTo address
    if (store.state.maps[this.mapId].hasOwnProperty("conf")) {
      this.sendTo = store.state.maps[this.mapId].conf.sendTo;
    }
  }

  async mounted(): Promise<void> {
    this.showContent();
    if (this.logged) {
      await this.$loadScript(this.handleSaveFeatureToDbScript);
    } else {
      if (this.enableEdit) {
        await this.$loadScript(this.handleEditedFeatureScript);
      }
    }
  }

  beforeUpdate(): void {
    if (this.forceEditMode) {
      this.handleEdit();
    }
    this.showContent();
  }

  beforeDestroy(): void {
    this.map.removeInteraction(this.select);
  }
}

function setTypesDescriptions(features: Feature[], typesDescriptions: IObjectTypeDescriptions) {
  features.forEach((feature) => {
    if (typesDescriptions !== null) {
      feature.set("typeDescription", typesDescriptions[feature.get("type")]);
    }
  });
  return features;
}
