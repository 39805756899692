






































import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store.ts";
import axios from "axios";
import sendform from "@/scripts/sendform.ts";

import { InterfaceFormResult, IUser } from "@/interfaces.ts";

@Component
export default class ContactForm extends Vue {
  /* ==PROPS== */
  // Map ID
  @Prop({ default: "" }) mapId!: string;

  @Prop({ default: true }) show!: boolean;

  /* ==DATA== */
  formData = {
    name: "",
    email: "",
    message: "",
  };

  sentSuccess = null as null | boolean;

  errorMessage = "";

  /* ==COMPUTED== */
  /**
   * @description Language object
   * @returns {any | null}
   */
  get lang(): any | null {
    if (store.state.maps[this.mapId].hasOwnProperty("lang")) {
      return store.state.maps[this.mapId].lang;
    } else {
      return null;
    }
  }

  // Public key for recaptcha
  get publicKey(): string {
    return store.state.publicKey;
  }

  // URL of PHP script for mailer
  get mailer(): string | null {
    if (store.state.maps[this.mapId].hasOwnProperty("conf")) {
      return store.state.maps[this.mapId].conf.mailer;
    } else {
      return null;
    }
  }

  // E-mail of addressee
  get sendTo(): string | null {
    if (store.state.maps[this.mapId].hasOwnProperty("conf")) {
      return store.state.maps[this.mapId].conf.sendTo;
    } else {
      return null;
    }
  }

  // Check for missing/wrong items in form
  get invalidForm(): boolean {
    if (
      this.formData.name === "" ||
      this.formData.email === "" ||
      this.formData.message === "" ||
      !this.validEmail(this.formData.email)
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Processed content of form to be sent
  get dataToSend() {
    return {
      emailto1: this.sendTo,
      ccemailto1: this.formData.email,
      subject: this.lang.contactForm.subject,
      content:
        this.lang.contactForm.name +
        ": " +
        this.formData.name +
        "\n" +
        this.lang.contactForm.email +
        ": " +
        this.formData.email +
        "\n" +
        this.formData.message,
    };
  }

  /* ==METHODS== */
  // Is email valid e-mail?
  validEmail(email: string) {
    const re = /(.+)@(.+){2,}\.(.+){2,}/;
    return re.test(email.toLowerCase());
  }

  // Handle closing of contact modal
  handleContactHide(): void {
    this.sentSuccess = null;
    this.$emit("contactHidden");
  }

  // Show result of sending
  handleResult(result: InterfaceFormResult): void {
    this.sentSuccess = result.success;
    if (!this.sentSuccess) {
      this.errorMessage = result.error;
    }
  }

  // Send e-mail
  handleSubmit(): void {
    store.commit("setUser", { user: { username: this.formData.name, email: this.formData.email }, id: this.mapId });
    sendform(this.dataToSend, {
      action: "sendmail",
      mailer: this.mailer as string,
      publicKey: this.publicKey,
      callback: this.handleResult,
    });
  }

  /* ==LIFECYCLE HOOKS== */
  // Prefill user name and user e-mail if available
  beforeUpdate(): void {
    if (store.state.maps[this.mapId].hasOwnProperty("user")) {
      this.formData.name = store.state.maps[this.mapId].user.username;
      this.formData.email = store.state.maps[this.mapId].user.email;
    }
  }
}
