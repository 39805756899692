/* This script is mostly recycled from the previous version. Complete overhaul is probably needed */

import { Fill, Stroke, Circle, Style, RegularShape, Text, Icon } from "ol/style.js";
import Feature from "ol/Feature";
import { TypesWithGeometry, ITypeSelect } from "../interfaces";

const processStyles = (stylesConf: any, langCode: string) => {
  const styleDefinition = {} as any;
  for (const key of Object.keys(stylesConf)) {
    const value = stylesConf[key];
    switch (key) {
      case "Point":
        styleDefinition.Point = {};
        for (const key2 of Object.keys(value)) {
          const value2 = value[key2];
          styleDefinition.Point[key2] = {}; // e.g. styles.Point.ship
          if (value2.hasOwnProperty("image")) {
            let image;
            switch (value2.image.type) {
              case "Circle":
                image = new Circle({
                  radius: value2.image.radius,
                  fill: new Fill({
                    color: value2.image.fill,
                  }),
                  stroke: new Stroke({
                    color: value2.image.stroke.color,
                    width: value2.image.stroke.width,
                  }),
                });
                break; // end of Circle
              case "RegularShape":
                image = new RegularShape({
                  points: value2.image.points,
                  radius: value2.image.radius,
                  fill: new Fill({
                    color: value2.image.fill,
                  }),
                  stroke: new Stroke({
                    color: value2.image.stroke.color,
                    width: value2.image.stroke.width,
                  }),
                });
                break; // end of Regular Shape
              case "Icon":
                image = new Icon({
                  src: value2.image.src,
                  scale: value2.image.scale,
                  imgSize: value2.image.imgSize, // due to Internet Explorer...
                });
                image.load(); // it is needed for rendering legend!!
                break; // end of Icon
            } // end of image.type
            styleDefinition.Point[key2] = new Style({
              image,
            });
          }
          if (value2.hasOwnProperty("text")) {
            const textStyleOptions = value2.text;
            if (textStyleOptions.hasOwnProperty("fill")) {
              textStyleOptions.fill = new Fill(textStyleOptions.fill);
            }
            if (textStyleOptions.hasOwnProperty("backgroundFill")) {
              textStyleOptions.backgroundFill = new Fill(textStyleOptions.backgroundFill);
            }
            styleDefinition.Point[key2] = new Style({ text: new Text(textStyleOptions) });
          }
        }
        break;
      case "LineString":
        styleDefinition.LineString = {};
        for (const key2 of Object.keys(value)) {
          const value2 = value[key2];
          styleDefinition.LineString[key2] = new Style({
            stroke: new Stroke({
              color: value2.stroke.color,
              width: value2.stroke.width,
              lineDash: value2.stroke.lineDash,
            }),
          });
        }
        break;
      case "Polygon":
        styleDefinition.Polygon = {};
        for (const key2 of Object.keys(value)) {
          const value2 = value[key2];
          styleDefinition.Polygon[key2] = new Style({
            stroke: new Stroke({
              color: value2.stroke.color,
              lineDash: [4],
              width: value2.stroke.width,
            }),
            fill: new Fill({
              color: value2.fill,
            }),
          });
        }
        break;
    }
  }
  return (feature: Feature, resolution: number): Style => {
    if (feature.getGeometry()) {
      const symbol = styleDefinition[feature.getGeometry()!.getType()][feature.get("type")];
      if (feature.getGeometry()!.getType() === "Point") {
        // sizing of icons
        if (typeof symbol === "undefined") {
          console.error("error at: " + feature); // error
        } else {
          if (symbol.getImage()) {
            // Some "invented" function for scale
            // symbol.getImage().setScale(2.1 / Math.log(resolution));
            symbol.getImage().setScale(2.1 / (Math.log(resolution * 10 + 10)));
          }
          if (symbol.getText()) {
            if (resolution > 2000) {
              symbol.getText().setText(""); // no text for small scale
            } else {
              symbol.getText().setText(feature.get("title")[langCode]);
            }
          }
        }
      }
      return symbol;
    } else {
      return new Style();
    }
  };
};

const processTypes = (stylesConf: any, langCode: string) => {
  if (stylesConf.hasOwnProperty("types")) {
    const result = {} as any;
    for (const key of Object.keys(stylesConf.types)) {
      result[key] = stylesConf.types[key][langCode];
    }
    return result;
  } else {
    return null;
  }
};

const processTypesWithGeometry = (stylesConf: any, types: { [key: string]: string }) => {
  const result = {} as TypesWithGeometry;
  // change types to format suitable for Bootstrap-Vue select options
  const selectTypes = {} as { [key: string]: { value: string; text: string } };
  for (const key of Object.keys(types)) {
    selectTypes[key] = { value: key, text: types[key] };
  }

  if (stylesConf.hasOwnProperty("Point")) {
    result.Point = [] as ITypeSelect[];
    for (const key of Object.keys(stylesConf.Point)) {
      result.Point.push(selectTypes[key]);
    }
  }
  if (stylesConf.hasOwnProperty("LineString")) {
    result.LineString = [] as ITypeSelect[];
    for (const key of Object.keys(stylesConf.LineString)) {
      result.LineString.push(selectTypes[key]);
    }
  }
  if (stylesConf.hasOwnProperty("Polygon")) {
    result.Polygon = [] as ITypeSelect[];
    for (const key of Object.keys(stylesConf.Polygon)) {
      result.Polygon.push(selectTypes[key]);
    }
  }
  return result;
};

export default processStyles;
export { processTypes, processTypesWithGeometry };
