





















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import store from "@/store.ts";
// @ts-ignore: Missing type definition
import VClickOutside from "v-click-outside";
import BaseLayer from "ol/layer/Base";
import LayerGroup from "ol/layer/Group";
import VectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature";
import Map from "ol/Map";

@Component({
  directives: {
    clickOutside: VClickOutside.directive,
  },
})
export default class SearchWidget extends Vue {
  /* ==PROPS== */
  // Map ID
  @Prop({ default: "" }) id!: string;
  @Prop({ default: "en" }) langCode!: string;
  @Prop() keyLayers!: number;

  /* ==DATA== */
  // show input field? (hidden on narow map)
  showInput = false;

  // text typed to the input
  searchedText = "";

  // Placeholder in input field
  placeHolder = "Searched text";

  // Array of layers to search in
  searchedLayers = [] as any;

  // selected layer for search; -1 means all layers
  selectedLayerIndex = -1;

  // Array of found features
  foundFeatures = [] as Feature[];

  // Array of suggested features
  suggestedFeatures = [] as Feature[];

  /* ==COMPUTED== */
  /**
   * @description Langugage object
   * @returns {any}
   */
  get lang(): any {
    if (store.state.maps[this.id].hasOwnProperty("lang")) {
      return store.state.maps[this.id].lang;
    } else {
      return null;
    }
  }

  get map(): Map {
    return store.state.maps[this.id].map;
  }

  get mapWidth(): number | null {
    if (this.map !== undefined) {
      return (this.map.getSize() as [number, number])[0];
    } else {
      return null;
    }
  }

  // Is map wide enough to show input field for text?
  get mapWide(): boolean {
    if (this.mapWidth === null) {
      return false;
    } else {
      return this.mapWidth > 991; // equals to -lg in Bootstrap
    }
  }

  // Show UI texts or icons only?
  get showUiTexts(): boolean | null {
    if (this.mapWidth !== null) {
      if (this.mapWidth > 1199) {
        // equals to -xl in Boostrap
        return true;
      } else {
        return false;
      }
    } else {
      return null;
    }
  }

  // Layer collection of the map
  get layerCollections(): BaseLayer[] {
    return this.map.getLayers().getArray(); // Generally can return  a layer or a layer group
  }

  // Text color variant for all layers
  get allLayersVariant(): "primary" | "dark" {
    if (this.selectedLayerIndex === -1) {
      return "primary";
    } else {
      return "dark";
    }
  }

  /* ==WATCHERS== */
  // Update searched layers if displayed layers changed
  @Watch("keyLayers")
  onKeyLayersChanged() {
    this.createSearchLayersList();
    this.selectedAllLayers();
  }

  /* ==METHODS== */
  // Hide input field if map is narrow and user clicked outside
  handleClickOutside(): void {
    if (this.showInput) {
      this.showInput = false;
    }
  }

  // Handle typing into input field
  handleInput(): void {
    if (this.searchedText.length > 2) {
      // suggestions
      const property = "title";
      const found = [] as Feature[];
      let searchedFeatures = [] as Feature[];
      if (this.selectedLayerIndex === -1) {
        this.searchedLayers.forEach((layer: any) => {
          searchedFeatures = searchedFeatures.concat(layer.features);
        });
      } else {
        searchedFeatures = this.searchedLayers[this.selectedLayerIndex].features;
      }
      this.suggestedFeatures = searchInFeatures(searchedFeatures, "title", this.langCode, this.searchedText);
    }
  }

  // Handle click on search button
  handleSearch(): void {
    if (!this.mapWide && !this.showInput) {
      this.showInput = true;
    } else {
      this.searchFeatures();
    }
  }

  // Do search
  searchFeatures(): void {
    const property = "title";
    const found = [] as Feature[];
    let searchedFeatures = [] as Feature[];
    if (this.selectedLayerIndex === -1) {
      this.searchedLayers.forEach((layer: any) => {
        searchedFeatures = searchedFeatures.concat(layer.features);
      });
    } else {
      searchedFeatures = this.searchedLayers[this.selectedLayerIndex].features;
    }
    this.foundFeatures = searchInFeatures(searchedFeatures, "title", this.langCode, this.searchedText);
    this.$bvModal.show(this.id + "-search-results");
  }

  // Handle selecting some layer
  selectedLayer(value: number) {
    this.selectedLayerIndex = value;
    this.searchedLayers.forEach((layer: any) => {
      layer.variant = "";
    });
    this.placeHolder = this.searchedLayers[value].title;
    this.searchedLayers[value].variant = "primary";
  }

  // Handle selecting all layers
  selectedAllLayers(): void {
    this.selectedLayerIndex = -1;
    this.searchedLayers.forEach((layer: any) => {
      layer.variant = "";
    });
    this.placeHolder = this.lang.ui.searchedText;
  }

  // Create list of searched layers
  createSearchLayersList(): void {
    this.searchedLayers.length = 0; // Reset searchLayers list
    if (this.layerCollections !== null) {
      this.layerCollections.forEach((collection: BaseLayer, index: number) => {
        if (collection instanceof LayerGroup) {
          collection
            .getLayers()
            .getArray()
            .forEach((layer, layerIndex) => {
              const layerStyle = {} as any;
              if (layer instanceof VectorLayer && layer.get("visible")) {
                const layerItem = {
                  title: layer.get("title"),
                  features: layer.getSource().getFeatures(),
                  variant: "",
                };
                this.searchedLayers.push(layerItem);
              }
            });
        }
      });
    }
  }

  // Handle selecting feature
  handleFeatureSelected(id: string): void {
    this.$bvModal.hide(this.id + "-search-results");
    this.$emit("zoomTo", id);
  }

  /* ==LIFECYCLE HOOKS== */
  created(): void {
    this.placeHolder = this.lang.ui.searchedText;
  }

  mounted(): void {
    this.createSearchLayersList();
  }
}

/* ==PRIVATE FUNCTIONS== */
// Actual search in features
function searchInFeatures(features: Feature[], property: string, langCode: string, searchedText: string): Feature[] {
  const found = [] as Feature[];
  features.forEach((feature: Feature) => {
    if (
      feature
        .get(property)
        [langCode].toLowerCase()
        .includes(searchedText.toLowerCase())
    ) {
      found.push(feature);
    }
  });
  return found;
}
