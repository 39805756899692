




import { Component, Vue, Prop } from "vue-property-decorator";
import Map from "ol/Map";
import ScaleLineOl, { Units } from "ol/control/ScaleLine";

@Component
export default class ScaleLine extends Vue {
  /* ==PROPS== */
  @Prop({ default: null, required: true }) map!: Map;

  /* ==DATA== */
  scaleLine = new ScaleLineOl({
    units: "metric",
  });

  /* ==METHODS== */
  handleClick(): void {
    switch (this.scaleLine.getUnits()) {
      case "metric":
        this.scaleLine.setUnits(Units.NAUTICAL);
        break;
      case "nautical":
        this.scaleLine.setUnits(Units.IMPERIAL);
        break;
      case "imperial":
        this.scaleLine.setUnits(Units.METRIC);
        break;
    }
  }

  /* ==LIFECYCLE HOOKS== */
  mounted(): void {
    this.scaleLine.setTarget(this.$el as HTMLElement);
    this.map.addControl(this.scaleLine);
  }
}
