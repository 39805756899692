






































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import store from "@/store.ts";
// @ts-ignore: Missing type definition
import VClickOutside from "v-click-outside";
import Map from "ol/Map";

@Component({
  directives: {
    clickOutside: VClickOutside.directive,
  },
})
export default class ToolsSelect extends Vue {
  /* ==PROPS== */
  // Map ID
  @Prop({ default: "" }) id!: string;

  // Which tool was closed?
  @Prop({ default: "" }) closedTool!: string;

  // Is editing enabled (allowed)?
  @Prop({ default: true }) enableEdit!: boolean;

  /* ==DATA== */
  // Active tool
  activeTool = "";

  /* ==COMPUTED== */
  /**
   * @description Langugage object
   * @returns {any}
   */
  get lang(): any {
    if (store.state.maps[this.id].hasOwnProperty("lang")) {
      return store.state.maps[this.id].lang;
    } else {
      return null;
    }
  }

  // Map
  get map(): Map {
    return store.state.maps[this.id].map;
  }

  // Map width
  get mapWidth(): number {
    return (this.map.getSize() as [number, number])[0];
  }

  /* ==WATCHERS== */
  // Deselect closed tool
  @Watch("closedTool")
  onClosedToolChange(value: string) {
    if (value !== "") {
      this.activeTool = ""; // Disable tool
    }
  }

  /* ==METHODS== */
  handleTool(value: string): void {
    if (this.activeTool === value) {
      this.activeTool = ""; // Disable tool
    } else {
      this.activeTool = value; // Enable tool
    }
    this.$emit("toolSelected", this.activeTool); // emit this.activeTool, not value!!
  }

  /* ==LIFECYCLE HOOKS== */
}

/* ==PRIVATE FUNCTIONS== */
