































import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store.ts";

@Component
export default class TheAbout extends Vue {
  /* ==PROPS== */
  // Map ID
  @Prop({ default: "" }) id!: string;

  // Version of app
  @Prop({ default: "" }) version!: string;

  @Prop({ default: "" }) aboutHtml!: string; // Text "About app"

  /* ==COMPUTED== */
  /**
   * @description Langugage object
   * @returns {any}
   */
  get lang(): any {
    if (store.state.maps[this.id].hasOwnProperty("lang")) {
      return store.state.maps[this.id].lang;
    } else {
      return null;
    }
  }
}
