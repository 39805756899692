import Vue from "vue";

import App from "./App.vue";
import store from "./store";
import { LayoutPlugin } from "bootstrap-vue";
Vue.use(LayoutPlugin);
import { CardPlugin } from "bootstrap-vue";
Vue.use(CardPlugin);
import { ButtonPlugin } from "bootstrap-vue";
Vue.use(ButtonPlugin);
import { FormInputPlugin } from "bootstrap-vue";
Vue.use(FormInputPlugin);
import { InputGroupPlugin } from "bootstrap-vue";
Vue.use(InputGroupPlugin);
import { FormCheckboxPlugin } from "bootstrap-vue";
Vue.use(FormCheckboxPlugin);
import { FormSelectPlugin } from "bootstrap-vue";
Vue.use(FormSelectPlugin);
import { DropdownPlugin } from "bootstrap-vue";
Vue.use(DropdownPlugin);
import { ModalPlugin } from "bootstrap-vue";
Vue.use(ModalPlugin);
import { ListGroupPlugin } from "bootstrap-vue";
Vue.use(ListGroupPlugin);
import { LinkPlugin } from "bootstrap-vue";
Vue.use(LinkPlugin);
import { ImagePlugin } from "bootstrap-vue";
Vue.use(ImagePlugin);
import { CollapsePlugin } from "bootstrap-vue";
Vue.use(CollapsePlugin);
import { PopoverPlugin } from "bootstrap-vue";
Vue.use(PopoverPlugin);
import { AlertPlugin } from "bootstrap-vue";
Vue.use(AlertPlugin);
import { BadgePlugin } from "bootstrap-vue";
Vue.use(BadgePlugin);
import { FormPlugin } from "bootstrap-vue";
Vue.use(FormPlugin);
import { FormTextareaPlugin } from "bootstrap-vue";
Vue.use(FormTextareaPlugin);

import { IconsPlugin } from "bootstrap-vue";
Vue.use(IconsPlugin);

// Plugin for loading external scripts
// @ts-ignore
import LoadScript from "vue-plugin-load-script";
Vue.use(LoadScript);

import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true; // Needed for coexistence with Bootstrap-Vue modals
Vue.use(VueClipboard);

declare module "vue/types/vue" {
  interface Vue {
    $loadScript: any; // LoadScript
  }
}

Vue.config.productionTip = false;

// get all HTML elements for maps accross the page
const elements = document.getElementsByClassName("olmap");

// array of IDs of these elements
const ids = [] as string[];

// array of relevant data-* attributes of these elements
const attributes = [] as any;

// process elements, get IDs and attributes
for (const el of elements) {
  ids.push(el.id);
  attributes.push({
    mapTitle: el.getAttribute("data-title"),
    conf: el.getAttribute("data-conf"),
    langConf: el.getAttribute("data-lang"),
    infowindow: el.getAttribute("data-infowindow"),
    infowindowTitle: el.getAttribute("data-infowindowtitle"),
    urlParams: el.getAttribute("data-urlparams"),
  });
}

// create Vue instances for every map (=== every map element)
for (let i = 0; i < ids.length; i++) {
  const app = new App();

  // unable to do this a better way yet
  // @ts-ignore
  app.store = store;
  // const store = new VueStorage();
  new Vue({
    // store: makeStore(),
    props: ["id", "mapTitle", "conf", "langConf", "infowindow", "infowindowTitle", "urlParams"],
    render: (h) =>
      h(app.$options, {
        attrs: {
          id: ids[i],
          mapTitle: attributes[i].mapTitle,
          conf: attributes[i].conf,
          langConf: attributes[i].langConf,
          infowindow: attributes[i].infowindow,
          infowindowTitle: attributes[i].infowindowTitle,
          urlParams: attributes[i].urlParams,
        },
      }),
  }).$mount("#" + ids[i]);
}
