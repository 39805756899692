// get and set params in URL
import "url-search-params-polyfill";

/*
  Position and zoom: @-123,456,7 // x = -123, y = 456, zoom = 7
  Layers: ~0:0,1;1:0,2:2,4 // baseLayers 0,1, overlaylayers 0, topicLayers 2,4
 */

// Get and return params from URL
const getParams = (id: string, urlParamsElem?: string) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  let params = null as null | undefined | string; // string of params
  const result = { // format of the result
    x: 0,
    y: 0,
    zoom: 0,
    layers: null as null | any,
    feature: null as null | string,
  };
  let returnResult = false; // if no result (no url params), we will return null
  if (urlSearchParams.has(id)) { // are there params in URL for map id?
    params = urlSearchParams.get(id);
  } else { // if not, use data-urlparams (see App.vue), if any
    params = urlParamsElem;
  }
  if (params !== "" && params !== null && params !== undefined) {
    if (typeof params === "string") {
      // Going to parse params string
      const positionOfAt = params.indexOf("@");
      const positionOfTilda = params.indexOf("~");
      const positionOfExclamation = params.indexOf("!");

      // Using regex for parse
      if (positionOfExclamation > -1) { // Zoom to feature?
        const regEx = /([a-zA-Z0-9])+/;
        const featureArray = params.slice(positionOfExclamation + 1).match(regEx) as any;
        if (featureArray !== null) {
          if (Array.isArray(featureArray) && typeof featureArray[0] === "string") {
            result.feature = featureArray[0];
            returnResult = true; // We have some params to return
          }
        }
      }

      if (positionOfAt > -1) { // Coordinates and zoom?
        const regEx = /(?<x>(-?\d+\.?\d*)),(?<y>(-?\d+\.?\d*)),(?<zoom>(-?\d+\.?\d*))/;
        const positionArray = params.slice(positionOfAt + 1).match(regEx) as any;
        if (positionArray !== null) {
          if (positionArray.hasOwnProperty("groups")) {
            if (
              typeof Number(positionArray.groups.x) === "number" &&
              typeof Number(positionArray.groups.y) === "number" &&
              typeof Number(positionArray.groups.zoom) === "number"
            ) {
              result.x = Number(positionArray.groups.x);
              result.y = Number(positionArray.groups.y);
              result.zoom = Number(positionArray.groups.zoom);
              returnResult = true; // We have some params to return
            }
          }
        }
      }

      if (positionOfTilda > -1) { // Layers?
        const regEx = /(?:\d:(?:\d*(?:,?\d+)*);?)+/;
        const tildaString = params.slice(positionOfTilda + 1).match(regEx);
        if (Array.isArray(tildaString) && typeof tildaString[0] === "string") {
          const tildaArrayTemp = tildaString[0].split(";");
          const tildaArray = [] as any[];
          tildaArrayTemp.forEach((tildaItem: string) => {
            const positionOfColon = tildaItem.indexOf(":");
            if (positionOfColon + 1 !== tildaItem.length) {
              tildaArray.push(
                tildaItem
                  .slice(positionOfColon + 1)
                  .split(",")
                  .map((item) => Number(item)),
              );
            } else {
              tildaArray.push([]);
            }
          });
          result.layers = tildaArray;
          returnResult = true; // We have some params to return
        }
      }
      if (returnResult) {
        return result;
      } else {
        return null; // Nothing in urlParams to return
      }
    } else {
      return null; // urlParams is not valid
    }
  } else {
    return null; // No urlParams in url nor in HTML data-urlparams
  }
};

const setParams = (id: string, params: { x: number; y: number; zoom: number; layers?: any }) => {
  // TODO: interface for layers
  let layers = "";
  if (Array.isArray(params.layers)) {
    layers = "~";
    params.layers.forEach((coll: any, index: number) => {
      layers += index + ":" + coll.join(",") + ";";
    });
    layers = layers.slice(0, -1);
  }
  const paramsToSet = "@" + params.x + "," + params.y + "," + params.zoom + layers;
  const urlSearchParams = new URLSearchParams(window.location.search);
  urlSearchParams.set(id, paramsToSet);
  if (window.history.pushState) {
    const newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      decodeURIComponent(urlSearchParams.toString());
    window.history.pushState({ path: newurl }, "", newurl);
  }
};

export { getParams, setParams };
