












































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import store from "@/store.ts";

import Map from "ol/Map";
import { unByKey } from "ol/Observable";
import { Point } from "ol/geom";
import GeometryType from "ol/geom/GeometryType";
import Draw, { DrawEvent } from "ol/interaction/Draw";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Circle as CircleStyle, RegularShape, Fill, Stroke, Style } from "ol/style";
import { getArea, getLength } from "ol/sphere";
import MapBrowserEvent from "ol/MapBrowserEvent";
import Event from "ol/events/Event";
import { EventsKey } from "ol/events";
import * as olProj from "ol/proj";
import { toStringHDMS } from "ol/coordinate";

@Component({})
export default class ToolsWidget extends Vue {
  /* ==PROPS== */
  // Map ID
  @Prop({ default: "" }) id!: string;
  @Prop({ default: "" }) selectedTool!: string;

  /* ==DATA== */
  // Show the widget?
  showWidget = false;

  // Liste to clicks
  listener = null as null | EventsKey;

  // Auxiliary Vector Source for "features" created by clicks
  source = new VectorSource();

  // How to display points
  pointStyle = new Style({
    image: new RegularShape({
      stroke: new Stroke({
        color: "orange",
        width: 2,
      }),
      points: 4,
      radius: 10,
      radius2: 0,
      angle: Math.PI / 4,
    }),
  });

  // Cursor style for getting position
  pointStyleCursor = new Style({
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: "orange",
      }),
      fill: new Fill({
        color: "rgba(255, 255, 255, 1)",
      }),
    }),
  });

  // How to display lines
  lineStyle = new Style({
    stroke: new Stroke({
      color: "orange",
      lineDash: [4, 4],
      width: 2,
    }),
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: "orange",
      }),
      fill: new Fill({
        color: "rgba(255, 255, 255, 1)",
      }),
    }),
  });

  // How to display polygons
  areaStyle = new Style({
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.2)",
    }),
    stroke: new Stroke({
      color: "orange",
      lineDash: [2, 2],
      width: 2,
    }),
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: "orange",
      }),
      fill: new Fill({
        color: "rgba(255, 255, 255, 1)",
      }),
    }),
  });

  // Auxiliary vector layer
  vector = new VectorLayer({
    source: this.source,
  });

  // Interaction for draw added to map
  interaction = null as null | Draw;

  // Default distances, areas and coordinates
  distance = 0;
  distanceUnit = "km";
  area = 0;
  areaUnit = "km2";
  coordinates = {
    x: 0,
    y: 0,
    longitude: undefined as number | undefined,
    latitude: undefined as number | undefined,
    geographic: "",
    geographicParsed: [] as Array<number | string>,
  };
  sketch = null as null | any;

  // Default geometry for added feature
  featureGeometry = "Point";

  /* ==COMPUTED== */
  /**
   * @description Langugage object
   * @returns {any}
   */
  get lang(): any {
    if (store.state.maps[this.id].hasOwnProperty("lang")) {
      return store.state.maps[this.id].lang;
    } else {
      return null;
    }
  }

  get map(): Map {
    return store.state.maps[this.id].map;
  }

  get mapWidth(): number {
    return (this.map.getSize() as [number, number])[0];
  }

  // Is map narrow?
  get mapWideSm(): boolean {
    return this.mapWidth > 575; // equals to -sm in Bootstrap;
  }

  // Computed values for distance
  get computedDistance(): number {
    let result = 0;
    switch (this.distanceUnit) {
      case "m":
        result = this.distance;
        break;
      case "km":
        result = this.distance / 1000;
        break;
      case "mi":
        result = this.distance / 1609.344;
        break;
      case "nm":
        result = this.distance / 1852;
        break;
      case "nmimp":
        result = this.distance / 1853.184;
        break;
      case "nleaimp":
        result = this.distance / 1853.184 / 3;
        break;
    }
    return Math.round(result * 1000) / 1000;
  }

  // Computed values for area
  get computedArea(): number {
    let result = 0;
    switch (this.areaUnit) {
      case "m2":
        result = this.area;
        break;
      case "km2":
        result = this.area / 1000 / 1000;
        break;
      case "mi2":
        result = this.area / 1609.344 / 1609.344;
        break;
      case "nm2":
        result = this.area / 1852 / 1852;
        break;
      case "nmimp2":
        result = this.area / 1853.184 / 1853.184;
        break;
      case "acre":
        result = this.area / 4046.8564224;
        break;
    }
    return Math.round(result * 1000) / 1000;
  }

  // Computed values for position from coordinates
  get latitudeFromDMS(): number {
    let latitude =
      (this.coordinates.geographicParsed[0] as number) +
      (this.coordinates.geographicParsed[1] as number) / 60 +
      (this.coordinates.geographicParsed[2] as number) / 3600;
    if (this.coordinates.geographicParsed[3] === "S") {
      latitude = -latitude;
    }
    return latitude;
  }

  get longitudeFromDMS(): number {
    let longitude =
      (this.coordinates.geographicParsed[4] as number) +
      (this.coordinates.geographicParsed[5] as number) / 60 +
      (this.coordinates.geographicParsed[6] as number) / 3600;
    if (this.coordinates.geographicParsed[7] === "W") {
      longitude = -longitude;
    }
    return longitude;
  }

  get invalidDMS(): boolean {
    if (this.latitudeFromDMS >= -90 && this.latitudeFromDMS <= 90) {
      if (this.longitudeFromDMS >= -180 && this.longitudeFromDMS <= 180) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  get invalidCoordinates(): boolean {
    if (this.coordinates.latitude === undefined || this.coordinates.longitude === undefined) {
      return true;
    } else {
      if (this.coordinates.latitude >= -90 && this.coordinates.latitude <= 90) {
        if (this.coordinates.longitude >= -180 && this.coordinates.longitude <= 180) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }

  /* ==WATCHERS== */
  // Handle change of tool
  @Watch("selectedTool")
  onSelectedToolChange(value: string) {
    if (value === "") {
      this.showWidget = false;
      this.distance = 0;
    } else {
      this.resetTools();
      this.vector.set("title", "Tools layer");
      this.map.addLayer(this.vector);

      this.showWidget = true;
      switch (value) {
        case "position":
          this.position();
          break;
        case "distance":
          this.measureDistance();
          break;
        case "area":
          this.measureArea();
          break;
      }
    }
  }

  /* ==METHODS== */
  // Is supposed number valid number
  validNum(num: any, min: number, max: number): boolean | null {
    if (num === undefined) {
      return null;
    }
    if (typeof num === "number") {
      if (num >= min && num <= max) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // Handle close of tool
  handleToolClosed(): void {
    this.resetTools();
    this.$emit("toolClosed", this.selectedTool);
  }

  // Center map at position given in Mercator
  goToMercator(): void {
    const feature = this.source.getFeatures()[0];
    const point = feature.getGeometry() as Point;
    point.setCoordinates([this.coordinates.x, this.coordinates.y]);
    this.map.getView().setCenter(point.getCoordinates());
  }

  // Handle go to LatLon position
  handleGoToLatLon(): void {
    [this.coordinates.x, this.coordinates.y] = olProj.fromLonLat([
      this.coordinates.longitude!,
      this.coordinates.latitude!,
    ]);
    this.goToMercator();
    this.coordinates.geographic = toStringHDMS([this.coordinates.longitude!, this.coordinates.latitude!]);
    this.fillParsedGeographic();
  }

  // Handle go to DMS position
  handleGoToDMS(): void {
    this.coordinates.latitude = this.latitudeFromDMS;
    this.coordinates.longitude = this.longitudeFromDMS;
    this.handleGoToLatLon();
  }

  // Fill values to position fields
  fillParsedGeographic(): void {
    this.coordinates.geographicParsed = this.coordinates.geographic.replace(/°|′|″/g, "").split(" ");
    const numIndexes = [0, 1, 2, 4, 5, 6];
    for (const index of numIndexes) {
      this.coordinates.geographicParsed[index] = parseInt(this.coordinates.geographicParsed[index] as string, 10);
    }
  }

  // Selected tool is position
  position(): void {
    this.vector.setStyle(this.pointStyle);
    this.interaction = new Draw({
      source: this.source,
      type: GeometryType.POINT,
      style: this.pointStyleCursor,
    });
    this.map.addInteraction(this.interaction);
    this.listener = this.map.on("singleclick", this.handleClick);
  }

  // Handle click when selected tool is "position"
  handleClick(event: MapBrowserEvent): void {
    if (this.source.getFeatures().length > 1) {
      // Remove previous feature, if any
      this.source.removeFeature(this.source.getFeatures()[0]);
    }
    this.coordinates.x = event.coordinate[0];
    this.coordinates.y = event.coordinate[1];
    const geographic = olProj.toLonLat([this.coordinates.x, this.coordinates.y]);
    [this.coordinates.longitude, this.coordinates.latitude] = geographic;
    this.coordinates.longitude = Math.round(this.coordinates.longitude * 10000) / 10000;
    this.coordinates.latitude = Math.round(this.coordinates.latitude * 10000) / 10000;
    this.coordinates.geographic = toStringHDMS(geographic);
    this.fillParsedGeographic();
  }

  // Selected tool is "distance"
  measureDistance(): void {
    this.vector.setStyle(this.lineStyle);
    this.interaction = new Draw({
      source: this.source,
      type: GeometryType.LINE_STRING,
      style: this.lineStyle,
    });
    this.map.addInteraction(this.interaction);
    this.listener = this.interaction.on("drawstart", (event: DrawEvent) => {
      this.source.clear();
      this.sketch = event.feature;
      this.sketch.getGeometry().on("change", (evt: Event) => {
        const geom = evt.target;
        this.distance = getLength(geom);
      });
    });
    this.interaction.on("drawend", (event: DrawEvent) => {
      this.sketch = null;
      // unByKey(listener);
    });
  }

  // Selected tool is "area"
  measureArea(): void {
    this.vector.setStyle(this.areaStyle);
    this.interaction = new Draw({
      source: this.source,
      type: GeometryType.POLYGON,
      style: this.areaStyle,
    });
    this.map.addInteraction(this.interaction);
    this.listener = this.interaction.on("drawstart", (event: DrawEvent) => {
      this.source.clear();
      this.sketch = event.feature;
      this.sketch.getGeometry().on("change", (evt: Event) => {
        const geom = evt.target;
        this.area = getArea(geom);
      });
    });
    this.interaction.on("drawend", (evt) => {
      this.sketch = null;
    });
  }

  // Handle "Add Feature" (geometry type already selected)
  handleAddFeature(): void {
    this.source.clear();
    if (this.interaction !== null) {
      this.map.removeInteraction(this.interaction);
    }

    let geometryType = "" as GeometryType;
    switch (this.featureGeometry) {
      case "Point":
        this.vector.setStyle(this.pointStyle);
        geometryType = GeometryType.POINT;
        break;
      case "LineString":
        this.vector.setStyle(this.lineStyle);
        geometryType = GeometryType.LINE_STRING;
        break;
      case "Polygon":
        this.vector.setStyle(this.areaStyle);
        geometryType = GeometryType.POLYGON;
        break;
    }
    this.interaction = new Draw({
      source: this.source,
      type: geometryType,
      style: this.vector.getStyle() || undefined,
    });
    this.map.addInteraction(this.interaction);
    this.listener = this.interaction.on("drawstart", (event: DrawEvent) => {
      this.source.clear();
      this.sketch = event.feature;
    });

    this.interaction.on("drawend", (event: DrawEvent) => {
      event.stopPropagation();
      this.map.removeInteraction(this.interaction as Draw);
      this.showWidget = false;
      this.$emit("addNewFeature", this.sketch.getGeometry(), this.source);
    });
  }

  // Tool widget is closed or the selected tool is changed
  resetTools(): void {
    this.distance = 0;
    this.area = 0;
    this.coordinates.x = 0;
    this.coordinates.y = 0;
    this.source.clear();
    if (this.listener !== null) {
      unByKey(this.listener);
    }
    if (this.interaction !== null) {
      this.map.removeInteraction(this.interaction);
    }
    this.map.removeLayer(this.vector);
  }

  /* ==LIFECYCLE HOOKS== */
}

/* ==PRIVATE FUNCTIONS== */
