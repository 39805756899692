


























































import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store.ts";

import axios, { AxiosResponse, AxiosError } from "axios";

@Component
export default class LoginForm extends Vue {
  /* ==PROPS== */
  // Map ID
  @Prop({ default: "" }) mapId!: string;

  // Show legend?
  @Prop({ default: true }) show!: boolean;

  /* ==DATA== */
  // Username
  username = "";

  // Password
  password = "";

  // Timeouts for alerts
  showLoginSuccess = 0;
  showLoginFail = 0;
  showLogoutSuccess = 0;
  showLogoutFail = 0;

  /* ==COMPUTED== */
  /**
   * @description Langugage object
   * @returns {any}
   */
  get lang(): any | null {
    if (store.state.maps[this.mapId].hasOwnProperty("lang")) {
      return store.state.maps[this.mapId].lang;
    } else {
      return null;
    }
  }

  get loginUrl(): string {
    return store.state.maps[this.mapId].conf.edit.loginUrl;
  }

  get logoutUrl(): string {
    return store.state.maps[this.mapId].conf.edit.logoutUrl;
  }

  get disableLogin(): boolean {
    return this.username === "" || this.password === "";
  }

  // Login status
  get logged(): boolean {
    return store.state.maps[this.mapId].logged || false;
  }

  // Username of the logged user
  get loggedUser(): string {
    return store.state.maps[this.mapId].loggedUser || "";
  }

  /* ==METHODS== */
  // Emit event legendHidden
  handleLoginHide(): void {
    // Must be reset for repeated login / logout
    this.showLoginSuccess = 0;
    this.showLogoutSuccess = 0;
    this.$emit("loginHidden");
  }

  // Handle login
  handleLogin(evt: Event): false {
    evt.preventDefault();
    const loginData = new FormData();
    loginData.append("username", this.username);
    loginData.append("password", this.password);
    console.log(loginData);
    axios({
      method: "post",
      url: this.loginUrl,
      data: loginData,
      headers: { "Content-Type": "multipart/form-data", "Accept": "application/json" },
      withCredentials: true,
    })
      .then((response) => {
        // handle success
        console.log(response.data);
        // forgot password
        this.password = "";
        this.showLoginSuccess = 3;
        store.commit("setLoggedUser", { username: response.data.username, id: this.mapId });
        store.commit("setLogged", { logged: true, id: this.mapId });
      })
      .catch((error) => {
        // handle error
        console.error(error.message);
        this.showLoginFail = 3;
      });

    return false;
  }

  // Handle logout
  handleLogout(evt: Event): void {
    evt.preventDefault();
    axios({
      method: "post",
      url: this.logoutUrl,
      data: {},
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
    })
      .then((response) => {
        // handle success
        console.log(response.data);
        store.commit("setLogged", { logged: false, id: this.mapId });
        store.commit("setLoggedUser", { username: "", id: this.mapId });
        this.showLogoutSuccess = 3;
      })
      .catch((error) => {
        // handle error
        console.error(error.message);
        this.showLogoutFail = 3;
      });
  }
}
