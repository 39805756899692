
















































































































































// Vue
import "ol/ol.css";
import { Component, Vue, Prop } from "vue-property-decorator";
import axios, { AxiosResponse, AxiosError } from "axios";
import store from "@/store.ts";
// OpenLayers
import Collection from "ol/Collection";
import Attribution from "ol/control/Attribution";
import Map from "ol/Map";
import Feature from "ol/Feature";
import View from "ol/View";
import BaseLayer from "ol/layer/Base";
import TileLayer from "ol/layer/Tile";
import GeoJSON from "ol/format/GeoJSON.js";
import VectorSource from "ol/source/Vector.js";
import LayerGroup from "ol/layer/Group";
import Stamen from "ol/source/Stamen";
import BingMaps from "ol/source/BingMaps";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import MapBrowserEvent from "ol/MapBrowserEvent";
import VectorLayer from "ol/layer/Vector";
import { defaults as defaultControls } from "ol/control";
import PinchRotate from "ol/interaction/PinchRotate";
import { Coordinate } from "ol/coordinate.js";
import { defaults as defaultInteractions } from "ol/interaction";
import { Point, LineString, Polygon, SimpleGeometry } from "ol/geom";
import { StyleFunction } from "ol/style/Style";
import Interaction from "ol/interaction/Interaction";
// Custom components
import InfoWindow from "@/components/InfoWindow.vue";
import ScaleLine from "@/components/ScaleLine.vue";
import LayerList from "@/components/LayerList.vue";
import SearchWidget from "@/components/SearchWidget.vue";
import TheLegend from "@/components/TheLegend.vue";
import ToolsSelect from "@/components/ToolsSelect.vue";
import ToolsWidget from "@/components/ToolsWidget.vue";
import ContactForm from "@/components/ContactForm.vue";
import SocialShare from "@/components/SocialShare.vue";
import LoginForm from "@/components/LoginForm.vue";
import TheAbout from "@/components/TheAbout.vue";
// Custom scripts
import * as processUrlParams from "@/scripts/processUrlParams.ts";
import { default as processStyles, processTypes, processTypesWithGeometry } from "@/scripts/processStyles.ts";
// Version
import { version } from "../../package.json"; // package version
// Interface
import { ILayersTypes, ITypesDescriptions } from "../interfaces";

@Component({
  components: {
    InfoWindow,
    LayerList,
    ScaleLine,
    SearchWidget,
    TheLegend,
    ToolsSelect,
    ToolsWidget,
    ContactForm,
    SocialShare,
    LoginForm,
    TheAbout,
  },
})
export default class MapElement extends Vue {
  /* ==PROPS== */
  // Map ID
  @Prop({ default: "" }) id!: string;

  // Path to configuration file for map
  @Prop({ default: "" }) confUrl!: string;

  // Path to template for infowindow content
  @Prop({ default: "" }) infowindow!: string;

  // Path to template for infowindow title
  @Prop({ default: "" }) infowindowTitle!: string;

  // Optionally can be urlParams placed in HTML element for map
  @Prop({ default: "" }) urlParamsElem!: string;

  /* ==DATA== */
  // Show Legend modal?
  showLegendModal = false;

  // Show Contact form modal?
  showContactModal = false;

  // Show Login form modal?
  showLoginModal = false;

  // Default value used for getting data from map service. Can be overwritten by conf file for map
  langCode = "en";

  // Is map created?
  // TODO to be changed! use store, probably
  mapCreated = false;

  // Coordinates of popup (to be improved)
  coordinate = {};

  // To be improved
  showInfoWindow = false;

  // map must know about editMode to handle click properly
  editMode = false;

  // force edit mode in InfoWindow
  forceEditMode = false;

  // Pixel coordinates of click (relative to map element);
  pixelClick = [0, 0] as Coordinate;

  // array of features selected by click
  selectedFeatures = [] as Feature[];

  // array of layers for features selected by click
  layersForSelectedFeatures = [] as VectorLayer[];

  // url parameters. TODO: interface
  urlParams = null as null | any;

  // show and update urlParams in URL/Adress bar?
  showUrlBarParams = true;

  // show map in full window
  fullScreen = false;

  // Text "About app"
  aboutHtml = "";

  // Version of the package, loaded from package.json
  version = "unknown";

  // default configuration for widgets
  widgetsDefault = {
    layerList: true,
    scaleLine: true,
    fullScreen: false,
    legend: false,
    tools: false,
    search: true,
    contact: true,
  };

  /* Configurations of base, overlay and topic layers (as objects) */

  // Default layer. Can be overwritten by conf file
  confBaseLayers = {
    title: {
      cs: "Podkladové mapy",
      en: "Base maps",
    },
    layers: [
      {
        title: {
          cs: "Open Street Map",
          en: "Open Street Map",
        },
        baseLayer: true,
        visible: true,
        source: {
          sourceType: "OSM",
        },
      },
    ],
  };
  confOverlayLayers = null as null | any;
  confTopicLayers = null as null | any;

  // Collections of layers
  baseLayersCollection = null as null | Collection<BaseLayer>;
  overlayLayersCollection = null as null | Collection<BaseLayer>;
  topicLayersCollection = null as null | Collection<VectorLayer>;

  // Layergroups
  baseLayersGroup = null as null | LayerGroup;
  overlayLayersGroup = null as null | LayerGroup;
  topicLayersGroup = null as null | LayerGroup;
  layersGroups = [] as LayerGroup[]; // all above

  // Enable anonymous editing of features? Can be overwritten in conf
  enableEdit = true;

  // TODO: not used yet!!! Are conditions for editing met? enableEdit === true && all sources loaded
  allowEdit = false;

  // Template for editing - default URL
  editTemplateUrl = "html/editTemplate.html";

  // Template for direct DB editing - default URL
  dbEditTemplateUrl = "html/dbEditTemplate.html";

  // Script for processing edited feature - default URL
  handleEditedFeatureScript = "js/handleFeatureForEdit.js";

  // Script for processing feature before saving to DB
  handleSaveFeatureToDbScript = "js/handleSaveFeatureToDb.js";

  // Edit layer
  editLayer = new VectorLayer({ source: new VectorSource() });

  // Click/hit tolerance
  hitTolerance = 15;

  // Vue key for watching changes in layers visibility (for Search widget)
  keyLayers = 0;

  // Tool selected from Tools
  selectedTool = "";

  // Which tool was closed?
  closedTool = "";

  /* ==COMPUTED== */
  /**
   * @description Langugage object - strings for UI
   * @returns {any}
   */
  get lang(): any {
    if (store.state.maps[this.id].hasOwnProperty("lang")) {
      return store.state.maps[this.id].lang;
    } else {
      return null;
    }
  }

  /**
   * @description Configuration object
   * @returns {any}
   */
  get conf(): any {
    if (store.state.maps[this.id].hasOwnProperty("conf")) {
      return store.state.maps[this.id].conf;
    } else {
      return null;
    }
  }

  /**
   * @description
   * Map object for this.id
   * @returns {Map}
   */
  get map(): Map {
    return store.state.maps[this.id].map;
  }

  // Title of map
  get mapTitle(): string {
    if (this.mapCreated) {
      return store.state.maps[this.id].conf.title;
    } else {
      return "";
    }
  }

  // Should LayerList be visible?
  get showLayerList(): boolean {
    return this.mapCreated && this.conf.widgets.layerList;
  }

  // Should ScaleLine be visible?
  get showScaleLine(): boolean {
    return this.mapCreated && this.conf.widgets.scaleLine;
  }

  // Should FullScreen button be visible?
  get showFullScreen(): boolean {
    return this.mapCreated && this.conf.widgets.fullScreen;
  }

  // Should AboutApp button be visible?
  get showAbout(): boolean {
    return this.mapCreated && this.aboutHtml !== "";
  }

  // Should Legend button be visible?
  get showLegend(): boolean {
    return this.mapCreated && this.conf.widgets.legend;
  }

  // Should Contact Form button be visible?
  get showContact(): boolean {
    return this.mapCreated && this.conf.widgets.contact;
  }

  // Should Tools button be visible?
  get showTools(): boolean {
    return this.mapCreated && this.conf.widgets.tools;
  }

  // Should Search widget be visible?
  get showSearch(): boolean {
    return this.mapCreated && this.conf.widgets.search;
  }

  // Should SocialShare widget be visible?
  get showSocialShare(): boolean {
    return this.mapCreated && this.conf.widgets.socialShare;
  }

  // Should Login button be visible?
  get showLogin(): boolean {
    return this.mapCreated && this.conf.hasOwnProperty("edit");
  }

  // Login status
  get logged(): boolean {
    return store.state.maps[this.id].logged || false;
  }

  // Username of the logged user
  get loggedUser(): string {
    return store.state.maps[this.id].loggedUser || "";
  }

  // Full or base url for social share
  get urlForSocialShare(): null | "baseUrl" | "fullUrl" {
    if (this.mapCreated) {
      if (this.conf.widgets.socialShare === "baseUrl") {
        return "baseUrl";
      } else {
        return "fullUrl";
      }
    } else {
      return null;
    }
  }

  // Width of map in pixels
  get mapWidth(): number {
    return (this.map.getSize() as [number, number])[0];
  }

  // TODO Use it instead of direct use of mapWidth. Not used yet! Is map wide enough?
  /* get mapWide() {
    return this.mapWidth > 991; // equals to -lg in Bootstrap
  } */

  /* ==METHODS== */

  // Fullscreen map
  handleFullScreen() {
    const bodyElement = document.body;
    this.fullScreen = !this.fullScreen;
    if (this.fullScreen) {
      bodyElement.classList.add("maximizedMap");
    } else {
      bodyElement.classList.remove("maximizedMap");
    }
    // Must redraw map for different size of parent element
    this.$nextTick(() => {
      this.map.updateSize();
    });
  }

  // Load map configuration and combine with url params for map
  async loadMapConf(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      const confData = await loadConf(this.confUrl);
      if (confData === "error") {
        alert("Error: configuration file could not be loaded");
        return;
      }
      if (this.urlParams !== null && this.urlParams !== undefined) {
        confData.center = [this.urlParams.x, this.urlParams.y];
        confData.zoom = this.urlParams.zoom;
      }
      // Now for simple configuration only. Will be propably replaced by some deeper merge method
      confData.widgets = { ...this.widgetsDefault, ...confData.widgets };

      this.langCode = confData.langCode || this.langCode;
      this.hitTolerance = confData.hitTolerance || this.hitTolerance;
      this.enableEdit = confData.enableEdit !== false; // True, unless confData.enableEdit is explicitly false
      this.editTemplateUrl = confData.editTemplateUrl || this.editTemplateUrl;
      this.handleEditedFeatureScript = confData.handleEditedFeatureScript || this.handleEditedFeatureScript;

      confData.mailer = confData.mailer || "php/mailformajax.php";
      confData.sendTo = confData.sendTo || "info@franklinova-expedice.cz";

      if (confData.showUrlBarParams !== undefined) {
        this.showUrlBarParams = confData.showUrlBarParams;
      }

      if (confData.hasOwnProperty("edit")) {
        this.dbEditTemplateUrl = confData.edit.dbEditTemplate || this.dbEditTemplateUrl;
        this.handleSaveFeatureToDbScript =
          confData.edit.handleSaveFeatureToDbScript || this.handleSaveFeatureToDbScript;
      } else {
        // Should be empty if db editing is not defined
        this.dbEditTemplateUrl = "";
        this.handleSaveFeatureToDbScript = "";
      }

      // Save processed conf data to store
      store.commit("setConf", { id: this.id, conf: confData });
      resolve();
    });
  }

  // Use somehow in future...
  /* const defaultBaseLayer = new TileLayer({
        source: new XYZ({
          url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        }),
      }); */
  // URGENT: Check the whole block!!!
  // Load layers configurations and process url params for layers
  async loadLayersConf() {
    // Index to array of defined layer groups. Some group(s) may be missing in configuration
    let layerGroupIndex = 0;

    // Load baseLayersConf
    if (this.conf.hasOwnProperty("baseLayersConf")) {
      this.confBaseLayers = await loadConf(this.conf.baseLayersConf);
    }

    // urlParams must be processed for both loaded and default baseLayersConf
    if (this.urlParams !== null && this.urlParams.hasOwnProperty("layers") && this.urlParams.layers !== null) {
      // TODO type definition for layer configuration instead of :any
      this.confBaseLayers.layers.forEach((layer: any, index: number) => {
        // Set visibility of baseLayers - only "mentioned" layers are visible
        if (this.urlParams.layers[layerGroupIndex].includes(index)) {
          layer.visible = true;
        } else {
          layer.visible = false;
        }
      });

      // TODO!! Is this correct place for incrementation???
      layerGroupIndex++;
    }

    if (this.conf.hasOwnProperty("overlayLayersConf")) {
      this.confOverlayLayers = await loadConf(this.conf.overlayLayersConf);
      if (this.urlParams !== null && this.urlParams.hasOwnProperty("layers") && this.urlParams.layers !== null) {
        // TODO type definition for layer
        this.confOverlayLayers.layers.forEach((layer: any, index: number) => {
          if (this.urlParams.layers[layerGroupIndex].includes(index)) {
            layer.visible = true;
          } else {
            layer.visible = false;
          }
        });
        layerGroupIndex++;
      }
    }
    if (this.conf.hasOwnProperty("topicLayersConf")) {
      this.confTopicLayers = await loadConf(this.conf.topicLayersConf);
      if (this.urlParams !== null && this.urlParams.hasOwnProperty("layers") && this.urlParams.layers !== null) {
        // TODO type definition for layer
        this.confTopicLayers.layers.forEach((layer: any, index: number) => {
          if (this.urlParams.layers[layerGroupIndex].includes(index)) {
            layer.visible = true;
          } else {
            layer.visible = false;
          }
        });
        layerGroupIndex++;
      }
    }
  }

  // Create layer collections and layer groups
  async createLayerGroups(): Promise<void> {
    if (this.confBaseLayers !== null && this.confBaseLayers.hasOwnProperty("layers")) {
      this.baseLayersCollection = createLayerCollection(this.confBaseLayers.layers, this.langCode);
      this.baseLayersGroup = new LayerGroup({ layers: this.baseLayersCollection });
      this.baseLayersGroup.set("title", this.lang.ui.baseLayers);
      this.layersGroups.push(this.baseLayersGroup);
    }
    if (this.confOverlayLayers !== null && this.confOverlayLayers.hasOwnProperty("layers")) {
      this.overlayLayersCollection = createLayerCollection(this.confOverlayLayers.layers, this.langCode);
      this.overlayLayersGroup = new LayerGroup({ layers: this.overlayLayersCollection });
      this.overlayLayersGroup.set("title", this.lang.ui.overlayLayers);
      this.layersGroups.push(this.overlayLayersGroup);
    }
    if (this.confTopicLayers !== null && this.confTopicLayers.hasOwnProperty("layers")) {
      // Need to return two types of results from createTopicsLayersCollection
      const topicLayersInfo = await createTopicLayersCollection(this.confTopicLayers.layers, this.langCode);
      this.topicLayersCollection = topicLayersInfo.topicLayersCollection;
      // Need this for database editing
      store.commit("setLayersTypes", { layersTypes: topicLayersInfo.layersTypesIds, id: this.id });
      store.commit("setObjectTypesDescriptions", {
        objectTypesDescriptions: topicLayersInfo.objectTypesDescriptions,
        id: this.id,
      });
      this.topicLayersGroup = new LayerGroup({ layers: this.topicLayersCollection });
      this.topicLayersGroup.set("title", this.lang.ui.topicLayers);
      this.layersGroups.push(this.topicLayersGroup);
    }
  }

  // Create map
  async createMap(): Promise<void> {
    const mapParent = document.getElementById(this.id);
    if (mapParent !== null) {
      const mapEl = mapParent.getElementsByClassName("olmap-map")[0] as HTMLElement;
      const map = new Map({
        target: mapEl,
        controls: defaultControls({ attributionOptions: { collapsible: true } }),
        interactions: defaultInteractions({ doubleClickZoom: false }),
        layers: this.layersGroups,
        view: new View({
          center: this.conf.center,
          zoom: this.conf.zoom,
        }),
      });
      map // prevent map rotating - causes problems on touch devices
        .getInteractions()
        .getArray()
        .forEach((interaction: Interaction) => {
          if (interaction instanceof PinchRotate) {
            map.removeInteraction(interaction);
          }
        });
      // Map is created

      map.once("rendercomplete", () => {
        store.commit("setMap", { id: this.id, map });
        if (this.enableEdit) {
          // Title set for debugging and detection in LayerList.vue
          this.editLayer.set("title", "Edit layer");
          // add editLayer
          map.addLayer(this.editLayer);
        }
        // let's handle possible feature select
        if (this.urlParams !== null) {
          if (this.urlParams.feature !== null) {
            this.zoomTo(this.urlParams.feature);
          }
        }
        map.on("singleclick", this.handleClick);
        // map.on("singleclick", this.handleClickNew);
        if (this.showUrlBarParams) {
          setUrlParams(this.map, this.id);
          this.map.on("moveend", () => {
            setUrlParams(this.map, this.id);
          });
        } else {
          // Handle case when I enter URL with selected feature but simultanously I have to remove URL params...
          // used on wiki or any other page with included map
          if (typeof this.urlParams === "object" && this.urlParams !== null) {
            // this.urlParams is object
            if (this.urlParams.feature !== null) {
              // Going to rremove feature from URL
              let currentUrl = window.location.href;
              let positionOfBegin = currentUrl.indexOf(this.id);
              let positionOfEnd = currentUrl.indexOf("&", positionOfBegin);
              if (positionOfEnd === -1) {
                positionOfEnd = currentUrl.length;
                positionOfBegin--; // We want to remove "?" or "&" too
              } else {
                if (currentUrl.charAt(positionOfBegin - 1) === "?") {
                  positionOfEnd++; // We want to remove next "&"
                }
              }
              const removeParams = currentUrl.substring(positionOfBegin, positionOfEnd);
              currentUrl = currentUrl.replace(currentUrl.substring(positionOfBegin, positionOfEnd), "");
              window.history.pushState({ path: currentUrl }, "", currentUrl);
            }
          }
        }
        if (this.showUrlBarParams) {
          this.map
            .getLayers()
            .getArray()
            .forEach((collection: LayerGroup, index: number) => {
              if (collection instanceof LayerGroup) {
                collection
                  .getLayers()
                  .getArray()
                  .forEach((layer, i: number) => {
                    layer.on("change:visible", () => {
                      setUrlParams(this.map, this.id);
                    });
                  });
              }
            });
        }

        this.mapCreated = true;
      });
    }
  }

  /**
   * @description Zoom to feature with given id
   * @param {string} featureId
   */
  zoomTo(featureId: string, open?: boolean): void {
    let feature = null as null | Feature;
    let openInfoWindow = true;
    if (open !== undefined) {
      openInfoWindow = open;
    }
    if (this.topicLayersCollection !== null) {
      this.topicLayersCollection.forEach((layer) => {
        if (layer.getSource().getFeatureById(featureId) !== null) {
          feature = layer.getSource().getFeatureById(featureId);
        }
      });
    }
    if (feature !== null) {
      const featureGeometry = feature.getGeometry();
      if (featureGeometry !== undefined) {
        if (featureGeometry.getType() === "Point") {
          // center and zoom;
          this.map.getView().setCenter((featureGeometry as Point).getCoordinates());
          if (this.map.getView().getZoom()! < 8) {
            this.map.getView().setZoom(8);
          }
        } else {
          this.map.getView().fit(featureGeometry as SimpleGeometry, { padding: [50, 50, 50, 50] });
        }
      }
      if (openInfoWindow) {
        // Select feature programmaticaly (open infowindow)
        this.selectedFeatures = [feature];
        this.showInfoWindow = true;
      } else {
        // Do nothing, infowindow is opened already
      }
    }
  }

  /**
   * @description Handle click to the map
   * @param {MapBrowserEvent} event
   */
  handleClick(event: MapBrowserEvent): void {
    if (this.selectedTool === "" && !this.editMode) {
      // Click will be handled here only if no tool is active (otherwise the tool will handle it itself)
      this.pixelClick = event.pixel; // Will be eventually used for map move by InfoWindow
      this.map.renderSync(); // due to delay in redrawing map - why?
      const features = [] as Feature[]; // array of features selected by click
      const layers = [] as VectorLayer[]; // array of vector layers corresponding to those features
      // (unable to get layer, which feature belongs to, later)

      this.map.forEachFeatureAtPixel(
        event.pixel, // For every feature at click
        (feature: Feature, layer: VectorLayer) => {
          features.push(feature); // Add feature
          layers.push(layer); // Add corresponding layers
        },
        {
          hitTolerance: this.hitTolerance, // Click tolerance for select
        },
      );
      if (features.length > 0) {
        // Was selected at least one feature?
        this.selectedFeatures = features;
        // Here we should use layers for editors!!!
        this.layersForSelectedFeatures = layers;
        this.coordinate = event.coordinate;
        this.showInfoWindow = true;
      } else {
        this.showInfoWindow = false; // Hide infowindow - click outside any feature
      }
    }
  }

  listenCloseInfoWindow(): void {
    this.showInfoWindow = false;
  }

  listenZoomTo(id: string): void {
    this.zoomTo(id, false);
  }

  listenZoomToOpenInfoWindow(id: string): void {
    this.zoomTo(id, true);
  }

  listenVisibilityChanged(): void {
    this.keyLayers++;
  }

  listenToolSelected(value: string): void {
    this.closedTool = ""; // must be erased because ToolsWidget watches for changes
    this.selectedTool = value;
  }

  listenToolClosed(value: string): void {
    this.selectedTool = "";
    this.closedTool = value;
  }

  listenEditModeChanged(value: boolean): void {
    this.editMode = value;
  }

  addNewFeature(featureGeom: Point | LineString | Polygon, source: VectorSource): void {
    // @ts-ignore
    const featureProps = emptyFeature(this.langCode, this.lang); // will call function
    featureProps.geometry = featureGeom;
    if (featureGeom.getType() === "Point") {
      this.pixelClick = this.map.getPixelFromCoordinate(featureGeom.getCoordinates() as Coordinate);
    } else {
      let coords = [] as Coordinate[];
      if (featureGeom.getType() === "LineString") {
        coords = (featureGeom as LineString).getCoordinates();
      } else {
        coords = (featureGeom as Polygon).getCoordinates()[0];
      }
      const sortedCoords = coords.sort((a: Coordinate, b: Coordinate) => {
        return a[0] - b[0];
      });
      this.pixelClick = this.map.getPixelFromCoordinate(sortedCoords[0]);
    }
    const newFeature = new Feature(featureProps);
    this.selectedFeatures = [newFeature];
    // TODO For editors, we must determine correct layer to add feature in
    // this.layersForSelectedFeatures = [this.editLayer];
    this.forceEditMode = true;
    this.showInfoWindow = true;
    this.$nextTick(() => {
      // we dont want forcedEditMode any more
      this.forceEditMode = false;
    });
  }

  /* ==LIFECYCLE HOOKS== */
  /**
   * @description
   */
  async mounted(): Promise<void> {
    this.version = version;
    // Get "About" content if defined
    const about = document.getElementById(this.id + "-about");
    if (about !== null) {
      this.aboutHtml = about.innerHTML;
    }

    // read params from URL
    this.urlParams = processUrlParams.getParams(this.id, this.urlParamsElem);
    // Load configuration for map
    await this.loadMapConf();
    // Load layers configuration
    await this.loadLayersConf();
    // Create layer groups
    await this.createLayerGroups();
    // Create map
    this.createMap();

    // Load script to handle editing of features
    if (this.enableEdit) {
      this.$loadScript(this.handleEditedFeatureScript)
        .then(() => {
          // Script is loaded, do something
        })
        .catch(() => {
          // Failed to fetch script
        });
    }
  }
}

/* ==PRIVATE FUNCTIONS== */

// General function for loading configuration files
async function loadConf(url: string): Promise<string | any> {
  return new Promise((resolve, reject) => {
    axios.get(url).then(
      (response: AxiosResponse) => {
        resolve(response.data);
      },
      (error: AxiosError) => {
        console.error(error);
        resolve("error");
      },
    );
  });
}

// Create collection of base or overlay layers
function createLayerCollection(confLayers: any, langCode: string): Collection<BaseLayer> {
  const layers = new Collection(); // array for all base layers
  confLayers.forEach((item: any) => {
    let doNotAdd = false;
    const layer = {} as any; // data for the currently processed tile layer
    layer.title = item.title[langCode];
    layer.baseLayer = item.baseLayer; // is it baselayer? True for baselayers only
    layer.visible = item.visible;
    if (item.source.hasOwnProperty("preload")) {
      layer.preload = item.preload;
    }
    const options = {} as any; // options for layer source
    if (item.source.hasOwnProperty("attributions")) {
      options.attributions = [new Attribution(item.source.attributions)];
    }
    switch (item.source.sourceType) {
      case "OSM":
        options.layer = item.source.layer;
        layer.source = new OSM(options);
        break;
      case "Stamen":
        options.layer = item.source.layer;
        layer.source = new Stamen(options);
        break;
      case "BingMaps":
        options.key = item.source.key;
        options.imagerySet = item.source.imagerySet;
        layer.source = new BingMaps(options);
        break;
      case "XYZ":
        options.url = item.source.url;
        if (item.source.attributions) {
          options.attributions = item.source.attributions + "<br>";
        }
        layer.source = new XYZ(options);
        break;

      default: {
        console.warn("unknown type of base layer");
        doNotAdd = true;
      }
    }
    if (!doNotAdd) {
      // Do not add unknown/uncreated layer
      layers.push(new TileLayer(layer));
    }
  });
  return layers as Collection<BaseLayer>;
}

// Create collection of topic layers
async function createTopicLayersCollection(confLayers: any, langCode: string) {
  // exp for editing
  const layersTypesIds = {} as ILayersTypes;
  const objectTypesDescriptions = {} as ITypesDescriptions;
  const layers = new Collection() as Collection<VectorLayer>;
  for (const item of confLayers) {
    // alert(item.layerId);

    let style: StyleFunction;
    let types: { [key: string]: string } = {}; // titles of types of feature
    const styleConf = await axios.get(item.style).then(
      (response) => {
        style = processStyles(response.data, langCode);
        types = processTypes(response.data, langCode);
        layersTypesIds[item.layerId] = processTypesWithGeometry(response.data, types);
      },
      (error: any) => {
        console.error(error);
        return "error";
      },
    );
    objectTypesDescriptions[item.layerId] = types;

    const vectorSource = new VectorSource({
      url: item.source,
      attributions: "<br>" + item.title[langCode] + ": " + item.attributions,
      format: new GeoJSON(),
    });
    // A bit strange waiting for "change", but vectorSource seems to be populated (and changed) only when displayed
    vectorSource.once("change", () => {
      const features = vectorSource.getFeatures();
      features.forEach((feature) => {
        if (types !== null) {
          feature.set("typeDescription", types[feature.get("type")]);
        }
      });
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      visible: item.visible,
    });
    if (style!) {
      vectorLayer.setStyle(style!);
    }
    vectorLayer.set("title", item.title[langCode]);
    // This id can be used for editing
    vectorLayer.set("id", item.layerId);
    layers.push(vectorLayer);
  }
  console.log(layersTypesIds);
  return {
    topicLayersCollection: layers as Collection<VectorLayer>,
    layersTypesIds,
    objectTypesDescriptions,
  };
}

// Get info about layers in the map and about their visibility
// TODO: get info about opacity
function getVisibleLayers(map: Map): BaseLayer[] {
  const layers = [] as any[];
  map
    .getLayers()
    .getArray()
    .forEach((collection: BaseLayer, index: number) => {
      if (collection instanceof LayerGroup) {
        layers[index] = [] as number[];
        collection
          .getLayers()
          .getArray()
          .forEach((layer, i: number) => {
            if (layer.get("visible")) {
              layers[index].push(i);
            }
          });
      }
    });
  return layers;
}

// Get data about current state of the map and pass it to processUrlParams.setParams
function setUrlParams(map: Map, id: string): void {
  const zoom = map.getView().getZoom() as number;
  const center = map.getView().getCenter() as number[];
  // get list of visible layers in groups
  const layers = getVisibleLayers(map);
  processUrlParams.setParams(id, { x: center[0], y: center[1], zoom, layers });
}
