
















import { Component, Prop, Vue } from "vue-property-decorator";
import "elm-pep";
import MapElement from "@/components/MapElement.vue";
import store from "@/store.ts";
import axios from "axios";

@Component({
  components: {
    MapElement,
  },
})
export default class App extends Vue {
  /* ==PROPS== */
  // Map title
  @Prop({ default: "" }) mapTitle!: string;

  // Map ID
  @Prop({ default: "" }) id!: string;

  // Path to configuration file
  @Prop({ default: "" }) conf!: string;

  // Path to language file
  @Prop({ default: "" }) langConf!: string;

  // Path to template for infowindow content
  @Prop({ default: "" }) infowindow!: string;

  // Path to template for infowindow title
  @Prop({ default: "" }) infowindowTitle!: string;

  // Optionally can be urlParams placed in HTML element for map
  @Prop({ default: "" }) urlParams!: string;

  /* ==DATA== */
  langLoaded = false; // is lang file loaded? === we can render map

  /* ==COMPUTED== */

  // Map itself
  get map() {
    return store.state.maps[this.id].map;
  }

  // lang definition
  get lang() {
    return store.state.maps[this.id].lang;
  }

  // map width
  get mapWidth() {
    if (this.map === undefined) {
      return 0;
    } else {
      return (this.map.getSize() as [number, number])[0];
    }
  }

  /* ==METHODS== */

  // Load language file.
  // TODO: Perhaps move to store?
  async loadLang() {
    const lang = await axios.get(this.langConf).then(
      (response) => {
        store.commit("setLang", { id: this.id, lang: response.data });
        this.langLoaded = true;
        return response.data;
      },
      (error: any) => {
        console.error(error);
        return "error";
      },
    );
  }

  /* ==LIFECYCLE HOOKS== */
  beforeMount() {
    store.commit("setId", this.id);
    this.loadLang();
  }
}
